// core
import React from 'react'
import { PrismicRichText } from "@prismicio/react";
import {  Link } from 'gatsby'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

// data-layer
import { setProductFlavorClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

// import Swiper core and required modules
import { Navigation } from "swiper";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

export const ProductRange = ({ slice }) => {
  // console.log('data ' , slice)

  const consentAccepted = useConsent()

  const navigation = {
    nextEl: '.swiper-next-custom',
    prevEl: '.swiper-prev-custom'
  }

  const swiperBreakPoints = {
    0: {
      slidesPerView: 1
    },
    768: {
      slidesPerView: 2
    },
    992: {
      slidesPerView: 3
    },
    1200: {
      slidesPerView: 4
    },
  };



  return (
      <section className="product-details">
        <div className="container-fluid inside-content-row py-0">
          <div className="row">
          {slice.items.map((sliceItem, index)=> {
            const catName = sliceItem.category.document.data.category_name
            const dlCatName = sliceItem.category.document.data.taxonomy_product_category || "";
            const catTagline = sliceItem.category.document.data.category_tagline
            const catLogo = sliceItem.category.document.data.category_logo
            const catBgImg = sliceItem.category.document.data.category_bg_image
            const catBgImgMob = sliceItem.category.document.data.category_bg_image_mobile
            const catInfo = sliceItem.category.document.data.category_info
            const catInfoSubtitle = sliceItem.category.document.data.category_info_subtitle
            const catItem = sliceItem.category.document.data.category_item
            const catNewText = sliceItem.category.document.data.category_new_text
            // console.log(catTagline)
            return (
              // If more than 1 category we can change to 'active category'
              catName === "ORIGINAL" && <div className="col-12 p-0" key={index}>
                <div className={`product-range-content product-range-content--${catName}`} data-cat={catName}>
                  <div className="product-range-header">
                    <h1 className="visually-hidden">{catName}</h1>
                    <div className="product-range-header__image-pct">
                      <img className={"product-range-header__image-img"} src={catLogo.url} alt={catLogo.alt} />
                    </div>
                    {catTagline ? <div className="product-range-header__tagline">{catTagline}</div> : null}
                    <div className={`product-range-header__info${catInfoSubtitle.richText.length === 0 ? ' mb-5' : ''}`}>
                      {catInfo.map((catInfoElement, catInfoIndex) => {
                        return (
                            <div key={catInfoIndex + '1'} className="product-range-header__info-item">
                              <img className="product-range-header__info-icon" src={catInfoElement.product_info_icon.url} alt={catInfoElement.product_info_icon.alt} />
                              <div className="product-range-header__info-title">{catInfoElement.product_info_name.text}</div>
                            </div>
                        );
                      })}
                    </div>
                    {
                      catInfoSubtitle.richText.length !== 0 ?
                          <div className='product-range-header__info-subtitle'>
                            <PrismicRichText field={catInfoSubtitle.richText}/>
                          </div>
                          : null
                    }
                  </div>
                  <div className={`product-range-content__body${catInfoSubtitle.richText.length === 0 ? ' mt-5' : ''}`}>
                    <div className='product-range-content__bg-image-pct'>
                      <img
                          className="product-range-content__bg-image-img"
                          src={catBgImg.url}
                          alt={catBgImg.alt}
                      />
                    </div>
                    <div className='product-range-content__bg-image-mobile-pct'>
                      <img
                          className="product-range-content__bg-image-mobile-img"
                          src={catBgImgMob.url}
                          alt={catBgImgMob.alt}
                      />
                    </div>

                    <Swiper
                        modules={[Navigation]}
                        navigation={navigation}
                        initialSlide={0}
                        speed={500}
                        breakpoints={swiperBreakPoints}
                    >
                      {catItem.map((catItemElement, catItemIndex) => { // eslint-disable-line array-callback-return
                        const catItemURL = catItemElement.category_item_link.document.url
                        const catItemImg = catItemElement.category_item_link.document.data.product_image_carousel.url ? catItemElement.category_item_link.document.data.product_image_carousel : catItemElement.category_item_link.document.data.product_image_png
                        const catItemName = catItemElement.category_item_link.document.data.product_name.richText
                        const catItemNewText = catNewText ? catNewText : catItemElement.category_item_link.document.data.product_new_text
                        const catItemType = catItemElement.category_item_link.document.data.product_type
                        const flavor =  catItemElement.category_item_link.document.data.taxonomy_product_flavor
                        const size =  catItemElement.category_item_link.document.data.taxonomy_product_size

                        if(catItemType !== "Pack") {
                          return (
                              <SwiperSlide key={catItemIndex + '2'}>
                                <Link
                                    className={`product-range-content__slide${catItemNewText ? " product-range-content__slide--new" : ""}`}
                                    to={catItemURL}
                                    onClick={() =>{
                                      // data-layer
                                      const dl = {
                                        flavor,
                                        category: dlCatName.toLowerCase(),
                                        position: catItemIndex + 1,
                                        url: catItemURL,
                                        location: 'homepage',
                                        size
                                      }
                                      setProductFlavorClickedEvent(dl, consentAccepted)
                                    }}
                                >
                                  <div className='product-range-content__image-pct'>
                                    <img
                                        className="product-range-content__image-img"
                                        src={catItemImg.url}
                                        alt={catItemImg.alt}
                                    />
                                  </div>
                                  <div className="product-range-content__name">
                                    <div className="product-range-content__name-text"><PrismicRichText field={catItemName} /></div>
                                    {catItemNewText ? <div className="product-range-content__name-new">{catItemNewText}</div> : null}
                                  </div>
                                </Link>
                              </SwiperSlide>
                          );
                        }
                      })}
                    </Swiper>
                    <button className="swiper-prev-custom">
                      <span className="visually-hidden">Prev</span>
                      <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" />
                    </button>
                    <button className="swiper-next-custom">
                      <span className="visually-hidden">Next</span>
                      <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" />
                    </button>
                  </div>
                </div>

              </div>
            )
          })}
          </div>
        </div>
      </section>
  );
}