import React, { useEffect } from 'react'
import { useCookies } from 'react-cookie'

const ConsentCookies = () => {
  const isBrowser = typeof window !== "undefined"
  const [cookies] = useCookies()

  useEffect(()=>{
    // Consent Cookies Consent Notice start
    // if(process.env.NODE_ENV !== 'development') {
      if(isBrowser && cookies?.adult) {
        window.semaphore = window.semaphore || []
        window.ketch=function(){
          window.semaphore.push(arguments)
        }
        var e = new URLSearchParams(document.location.search)
        var o = e.has("property") ? e.get("property") : "white_claw_zero_us"
        const script = document.createElement("script")
        script.src = `https://global.ketchcdn.com/web/v2/config/mag_global/${o}/boot.js`
        script.async = true
        script.defer = true
        script.type = "text/javascript"
        document.head.appendChild(script)
      }
    // }
    // Consent Cookies Consent Notice end
  },[cookies?.adult])

  return (<></>)
}

export default ConsentCookies