import { HeroSection } from "../HeroSection";
import { HeroVideoSection } from "../HeroVideoSection";
import { YouTubeVideoSection } from "../YoutubeVideoSection";
import { InstagramSection } from "../InstagramSection";
import { CrossLink } from "../CrossLink";
import { PromoModule } from  '../PromoModule';
import { CampaignRangeSection } from './CampaignRangeSection';
import { CampaignCtaSection } from './CampaignCtaSection'


export const components = {
  hero_section: HeroSection,
  hero_video_section: HeroVideoSection,
  youtube_video_section: YouTubeVideoSection,
  crosslink: CrossLink,
  instagram_section: InstagramSection,
  promo_module: PromoModule,
  campaign_range_section: CampaignRangeSection,
  campaign_cta_section: CampaignCtaSection
}