import React from 'react'

export const LocatorHero = ({slice}) => {
  const title = slice.primary.title?.text || ''
  const banner = slice.primary.banner?.url || ''

  return <>
    <section className='prod-locator__hero' style={{backgroundImage: `url(${banner})`}}>
      {title && <h1 className='prod-locator__title'>{title}</h1>}
    </section>
    <section className='prod-locator__key-message'>
      <p className='prod-locator__key-message-text pb-4'>
        White Claw 0% Alcohol is available at select online and in-store retailers.
      </p>
      <p className='prod-locator__key-message-text'>
        If you are unable to find it using our product locator below check back soon for updated availability near you.
      </p>
    </section>
  </>
}
