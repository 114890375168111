import React from 'react';

const Locator = () => {
  //please, do not remove or change id name
  return <div className='locator-container-wrap'>
    <h2>FIND IT NEAR YOU</h2>
    <p> White Claw<sup className='trademark'>TM</sup> 0% Alcohol is available at select online and in-store retailers.</p>
    <a id='locator-anchor'></a>
    <div id="locator-container" className='locator-container'></div>
  </div>;
}

export default Locator