import React from 'react'
import { Link } from 'gatsby'
import { useLocation } from "@reach/router";

// data-layer
import { setLearnMoreClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

export const CrossLink = ({ slice }) => {
  const location = useLocation()
  const consentAccepted = useConsent()

  const ctaButton = {
    href: slice.primary.cta_button_link?.url || '',
    text: slice.primary.cta_button_text?.text || ''
  }

  return (
      <section className="crosslink-section">
        <Link
            to={ctaButton.href}
            target="_blank"
            title={ctaButton.text}
            className="button-transparent crosslink__cta-button"
            onClick={()=> {
              const dl = {
                url: ctaButton.href,
                referrer: location.pathname,
                name: ctaButton.text.toLowerCase()
              }
  
              setLearnMoreClickedEvent(dl, consentAccepted)
            } }
            >
          {ctaButton.text}
        </Link>
      </section>
  )
}