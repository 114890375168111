import { useState, useEffect } from 'react'

export const useIntersection = (element, rootMargin) => {
    const [isVisible, setState] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setState(entry.isIntersecting);
            }, { rootMargin }
        );

        element.current && observer.observe(element.current);

        return () => observer.unobserve(element.current);
    }, []);

    return isVisible;
};

// Leaving 'type' and 'otAccepted' in the function for future use with Segment
export const dataTrack = (eventName, dataObject, type, otAccepted) => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({
		...(eventName && { 'event': eventName }),
		...dataObject
	})

  // If segment is needed install same as WC US and configure in gatsby config file
  // if(otAccepted) {
  //   if(type === 'page') {
  //     window.analytics.page({...dataObject})
  //   } else {
  //     window.analytics.track(convertString(eventName), dataObject && {...dataObject})
  //   }
  // }
}