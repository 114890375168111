export const checkDOB = (values) => {
  const birthdate = new Date(values.year, values.month - 1, values.day)
  const birthdateYear = birthdate.getFullYear()
  const birthdateTimestamp = Math.floor(birthdate.getTime() / 1000)
  const todayTimestamp = Math.floor(Date.now() / 1000)
  const todayYear = new Date(todayTimestamp * 1000).getFullYear()
  const ageInSeconds = todayTimestamp - birthdateTimestamp
  const ageInYears = ageInSeconds / (60 * 60 * 24 * 365.25)
  const validAgeWholeYears = 21
  const ageValid = ageInYears >= validAgeWholeYears

  const isTodayBirthday =
      new Date(todayTimestamp * 1000).getDate() === birthdate.getDate() &&
      new Date(todayTimestamp * 1000).getMonth() === birthdate.getMonth() &&
      (todayYear - birthdateYear === validAgeWholeYears)

  return ageValid || isTodayBirthday

}