// core
import * as React from 'react'
import { Link } from "gatsby";

// data layer
import { setBuyNowClickedEvent } from '../assets/js/data-layer';
import { useConsent } from '../components/ConsentContext'

export const FindNearYou = ({ slice }) => {
  const consentAccepted = useConsent()

  const data = slice.primary;
  const items = slice.items;

  return (
      <section className="find-near-you__section">
        <div className="find-near-you">
          {data.title.text && <h2 className="find-near-you__title">{ data.title.text }</h2>}
          <Link
              to={data.button?.url || ''}
              target="_blank"
              title={data.button_text.text}
              className="button-transparent find-near-you__button my-5"
              onClick={() => {
                if(data.button && data.button.url === "/locator"){
                  const dl = {
                    flavor: '',
                    category: ''
                  }
  
                  setBuyNowClickedEvent(dl, consentAccepted)
                }
              }}
          >
            {data.button_text.text || ''}
          </Link>
          <div className="find-near-you__description">{data.description.text}</div>
          <div className="find-near-you__info row">
            {items.map((element, index) => {
              return (
                  <div key={index} className="find-near-you__item col">
                    {element.icon.url && <img className="find-near-you__item-icon" src={element.icon.url} alt={element.icon.alt} />}
                    {element.icon_title.text && <div className="find-near-you__item-text">{element.icon_title.text}</div>}
                  </div>
              );
            })}
          </div>
        </div>
      </section>
  );
};