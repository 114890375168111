import React from 'react'

const FieldErrorMessage = ( { error } ) => {
    return (
        <div className="help-block with-errors">
             <ul role="alert" className={"list-unstyled " + (!error ? "d-none" : "")}>
                <li>{error}</li>
            </ul>
        </div>
    )
}

export default FieldErrorMessage