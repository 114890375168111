import React, {useEffect, useRef} from 'react'
import { Link } from 'gatsby'

// constants
import { LANG } from "../constants/languages";

//assets
import useWindowSize from "../hooks/useWindowSize";



export const HeroVideoSection = ({ slice }) => {
  const { width } = useWindowSize();
  const playerRef = useRef()

  const button = {
    href: slice.primary.button_link.url || '',
    text: slice.primary.button_text.text || '',
  }

  const video = {
    desktop: slice.primary.page_language === LANG.EN ? '' : '',
    mobile: slice.primary.page_language === LANG.EN ? '' : ''
  }

  useEffect(()=>{
    if (!playerRef?.current) return

    playerRef.current.addEventListener('suspend', () => {
      playerRef.current.play()
    });

  },[])

  return (
      <section className='video-hero'>
        <a href={button.href || ''}>
        <video className='video-hero__video'
               ref={playerRef}
               src={`${ width <= 768 ? video.mobile : video.desktop}`}
               width="100%"
               height="100%"
               playsInline
               autoPlay
               muted
               loop
               poster=""/>
        </a>
        <Link
            to={button.href}
            title={button.text}
            className="video-hero__button button-white"
        >
          {button.text}
        </Link>
      </section>
  )
}
