// core
import React, {useEffect, useState} from 'react'

// data-layer
import { useConsent } from '../components/ConsentContext'
import { useLocation } from "@reach/router";
import {
  setSocialIconClickedEvent,
  setSubscriptionSignUpEvent,
  setLearnMoreClickedEvent
} from '../assets/js/data-layer'

// prop hasTrackAnonymousId = true if embedded script has class  'wp-container' and attr 'data-wishpond-script'
const WishpondPage = ({id, hasTrackAnonymousId = true, type, isMicrosite = false, wpHeight = '100vh', wpMarginTop}) => {
  const [wpHref, setWpHref] = useState('')
  const consentAccepted = useConsent()
  const location = useLocation()

  const wpScript = "//cdn.wishpond.net/connect.js?merchantId=1816671&writeKey=cce5bbc9e5f3"
  const wpCurHref = `https://embedded.wishpondpages.com/lp/${id}/`

  const isBrowser = typeof window !== "undefined"
  const analytics = isBrowser ? window.analytics : undefined

  const wpStyles = {}

  if(type !== "form" || undefined && wpHeight) {
    wpStyles.minHeight = wpHeight
  }

  if(wpMarginTop) {
    wpStyles.marginTop = wpMarginTop
  }

  wpStyles.marginBottom = '-7px'

  useEffect(() => {
    setWpHref(wpCurHref)

    //append WP script
    const script = document.createElement("script")
    script.src = wpScript
    script.async = true

    script.onload = function(){
      console.log("Wishpond script has been loaded")

      // data layer
      window.addEventListener('message', handleCallbackMessage, false);
    }

    document.body.appendChild(script)

    //add scroll to embedded page
    if(!isMicrosite) {
      setTimeout(() => {
        const iframe = document.getElementById(`wp-page-${id}`);
        iframe.style.minHeight = "100vh"
        window.addEventListener("message", (event) => {
          getIframeHeight(event, iframe)
        }, false);
      }, 2000);
    }

    return () => {
      window.removeEventListener('message', handleCallbackMessage, false);
    }

  },[wpCurHref, isMicrosite, id])

  function getIframeHeight(event, iframe) {
    if(event.data.type === "content_height"){
      iframe.style.height = `${event.data.data.height}px`
    }
  }

  useEffect(() => {
    // trackAnonymousId
    if(!analytics){
      return
    }

    // wait for wishpond page loading
    setTimeout(()=>{
      const  anonymous_id = analytics.hasOwnProperty('user')
              ? analytics.user().anonymousId() : analytics.group().hasOwnProperty('anonymousId')
              ? analytics.group().anonymousId() : ''

      if(hasTrackAnonymousId){
        const hrefWithAnonymousID =`${wpCurHref}?anonymous_id=${anonymous_id}`
        setWpHref(hrefWithAnonymousID)
      } else {
        const message = JSON.stringify({ anonymous_id });
        document.getElementById(`wp-page-${id}`)?.contentWindow.postMessage(message, "*");
      }

    }, 2500)

  },[analytics, hasTrackAnonymousId, id, wpCurHref])

  useEffect(() => {
    if(isBrowser) {
      window.addEventListener('message', (event) => {
        if (event.data.type === 'DLClickEvent') {
          const dl = {
            url: event.data?.clickURL,
            referrer: location.pathname,
            name: event.data?.text.toLowerCase()
          }
          setLearnMoreClickedEvent(dl, consentAccepted)
        }
      });
    }
  },[])

  const handleCallbackMessage = (event) => {
    if(isJSON(event.data)){
      const data = JSON.parse(event.data)
      if(data.event){
        if(data.event === 'social_icon_clicked'){
          setSocialIconClickedEvent({
            platform: data.social_platform,
            location: 'social'
          }, consentAccepted)
        }else if(data.event === 'email_subscription_signup'){
          setSubscriptionSignUpEvent({
            location: 'social'
          }, consentAccepted)
        }else if(data.event === 'buy_now_clicked'){
          sessionStorage.setItem("buy_now_clicked", "social")
        }
      }
    }
  }

  const isJSON = (str) => {
    try{
      JSON.parse(str);
      return true
    }catch(e) {
      return false
    }
  }

  return (
      <div
          className="wishpond-campaign"
          data-wishpond-id={id}
          data-wishpond-href={wpHref}
          style={wpStyles}>
      </div>
  )
}

export default WishpondPage