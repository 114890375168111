// core
import React, {useState, useEffect} from 'react'
import {scroller} from "react-scroll";
import {useLocation} from "@reach/router"
import axios from 'axios'

import {graphql} from 'gatsby'
import {withPrismicPreview} from "gatsby-plugin-prismic-previews"
import {PrismicRichText, SliceZone} from '@prismicio/react'

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import ImgWithFallback from '../components/ImgWithFallback'
import Locator from '../components/Locator'
import {useConsent} from '../components/ConsentContext'
import Reviews from "../components/Reviews";

// slices
import {pdpComponents} from '../slices/product-details'

// resources
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronDown} from '@fortawesome/free-solid-svg-icons'

// constants
import {GOOGLE_MAP_API_URL} from "../constants/baseUrl"

import {MIK_MAK_SCRIPT} from "../constants";

// data-layer
import {
  setProductFlavorViewedEvent,
  setNutritionIngClickedEvent,
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setBuyNowClickedEvent,
  setGetDirectionsEvent
} from "../assets/js/data-layer";

const CATEGORIES = {
  ORIGINAL: 'original'
}

const PRODUCT_TYPE = {
  CAN: 'Can',
  PACK: 'Pack'
}

const PRODUCT_SIZES_EN = {
  can12oz: '1 can (12 oz)',
}

const ProductDetails = ({data}) => {
  const [selectedNutritionSlice, setSelectedNutritionSlice] = useState([])
  const [isTableActive, setTableActive] = useState(false)
  const [isServingActive, setServingActive] = useState(false)
  const [dropdownSizes, setDropdownSizes] = useState([])
  const [userSelection, setUserSelection] = useState(null)

  const consentAccepted = useConsent()
  const location = useLocation()
  const siteData = data.site.siteMetadata

  const pageContent = data.prismicProductDetailsPage
  const pageData = data.prismicProductDetailsPage.data
  const sliceData = pageData.body

  const {lang, type, url} = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  // GTINs for MIKMAK widget
  const productId = pageData.product_id || ''

  // select first GTIN for BV widget
  const productIdBV = productId.split(",").splice(0, 1).join("")

  const category = pageData.product_category.document.data.category_name.toLowerCase() || ''

  const currentCategory = {
    original: category === CATEGORIES.ORIGINAL,
  }

  const productType = {
    can: pageData.product_type === PRODUCT_TYPE.CAN,
    pack: pageData.product_type === PRODUCT_TYPE.PACK,
  }

  // taxonomy
  const flavor = pageContent.data.taxonomy_product_flavor
  const size = pageContent.data.taxonomy_product_size


  // set RTBs icons and text from pdp or from category
  const rtbItemsList = pageData.product_info.length !== 0 ? pageData.product_info : pageData.product_category.document.data.category_info

  const productName = pageData.product_name.richText
  const productDescription = pageData.product_description.richText

  const productLocatorBtnText = pageData.product_action_title || ''

  const productCtaBtnLink = pageData.cta_button_link?.url || ''
  const productCtaBtnText = pageData.cta_button_text?.text || ''


  const categoryInfoSubTitle = pageData.product_category.document.data.category_info_subtitle.richText

  const ToggleTableClass = () => {
    setTableActive(!isTableActive)

    // nutrition_ingredients_clicked data-layer
    const product = {
      name: pageData.product_name.text,
      category: pageData.product_category.document.data.category_name.toLowerCase()
    }
    setNutritionIngClickedEvent(product, consentAccepted)
  }

  const ToggleServingClass = () => {
    setServingActive(!isServingActive)
  }

  const checkForSlice = (sliceToCheck) => {
    return sliceData.some((element) => {
      return element.slice_type === sliceToCheck
    })
  }

  // filter slices by can size
  const getNutritionSliceToRender = (selectedSize) => {

    const filtered = sliceData.reduce((acc, slice) => {
      if (slice.slice_type === 'nutrition_and_ingredients_table') {
        let n = {
          ...slice, items: slice.items.filter((item) => {
            return item.product_size === selectedSize
          })
        }
        acc.push(n)
      }

      return acc
    }, [])

    setSelectedNutritionSlice(filtered)
    return filtered
  }

  const renderSliceType = (type) => {
    const sliceToRender = []
    sliceData.forEach((element) => {
      if (element.slice_type === type) {
        sliceToRender.push(element)
      }
    })
    return sliceToRender
  }

  // set default selected size value for dropdown
  useEffect(() => {
    setUserSelection(PRODUCT_SIZES_EN.can12oz)
  }, [])


  // set new slice when size was changed
  useEffect(() => {
    if (userSelection) {
      getNutritionSliceToRender(userSelection)
    }
  }, [userSelection])


  // set list of all sizes for dropdown
  useEffect(() => {
    const sizes = sliceData.reduce((acc, slice) => {

      if (slice.slice_type === 'nutrition_and_ingredients_table') {
        slice.items.filter((sl) => {
          acc.push({size: `${sl.product_size}`})
        })
      }
      return acc
    }, [])

    setDropdownSizes(sizes)

  }, [])

  useEffect(() => {
    const category = pageContent.data.product_category.document.data.category_name.toLowerCase() || ''
    const flavor = pageContent.data.taxonomy_product_flavor
    const size = pageContent.data.taxonomy_product_size

    setProductFlavorViewedEvent({flavor, category, size}, consentAccepted)
  }, [pageContent, consentAccepted])

  const isNutritionalTableSlice = checkForSlice('nutrition_and_ingredients_table')
  const allFlavorsSlice = renderSliceType("all_flavors")
  const allFlavorsVPSlice = renderSliceType("all_flavors_vp")

  // all slice except right column slices
  const filteredSlices = sliceData.filter((slice) => {
    if (slice.slice_type === 'promo_module') {
      let category = pageContent.data.product_category.document.data.category_name.toLowerCase() || ''
      category = (category === 'original') ? 'original non-alcoholic' : category

      slice.flavor = pageContent.data.taxonomy_product_flavor
      slice.category = category
    }
    return slice.slice_type !== 'nutrition_and_ingredients_table' && slice.slice_type !== 'all_flavors' && slice.slice_type !== 'all_flavors_vp'
  })

  // push product Ids (GTINs) to mikmak dataLayer and added TAG
  useEffect(() => {

    const script = document.createElement("script")
    script.type = 'text/javascript'
    script.innerText = `
      window.swnDataLayer = { product_eans: "${productId}" };
      ${MIK_MAK_SCRIPT}
    `

    // data layer - MikMak events
    const checkMikMakDataLayer = setInterval(() => {
      if (window.swnDataLayer) {
        window.swnDataLayer.widget_callback = async (data) => {
          try {
            if (data.action === 'buy') {
              // retailer selected event
              const supplier = data.store.name.toLowerCase()
              const flavor = pageContent.data.taxonomy_product_flavor
              let category = pageContent.data.product_category.document.data.category_name.toLowerCase() || ''
              category = (category === 'original') ? 'original non-alcoholic' : category
              const size = data.product.pkg ? data.product.pkg.toLowerCase() : ''
              const price = `${data.product.price}`

              setRetailerSelectedEvent({
                'supplier': supplier,
                'flavor': flavor,
                'category': category,
                'size': size,
                'price': price
              }, consentAccepted)
            }else if(data.action === 'clkRoute') {
              // 'get_directions' event
              const supplier = data.store.name.toLowerCase()

              setGetDirectionsEvent({
                'url': location.href,
                'supplier': supplier
              }, consentAccepted)
            } else if (data.action === 'geoloc') {
              // 'locator_address_searched' event
              const zipCode = data.location.zipCode
              if (zipCode) {
                const locationData = await getAddressFromZipCode(zipCode)
                if (locationData) {
                  setLocatorAddressSearchedEvent(locationData, consentAccepted)
                }
              }
            }
          } catch (e) {
            console.error(e)
          }
        }

        clearInterval(checkMikMakDataLayer)
      }
    }, 300)

    document.body.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)

      clearInterval(checkMikMakDataLayer)
    }
  }, [productId, consentAccepted])


  // data layer - locator pageview event
  useEffect(() => {
    const dlData = {
      title: pageData.meta_title.text || siteData.title,
      url: location.href,
      path: location.pathname,
      referrer: (sessionStorage.getItem('previous_page_url')) ?
          sessionStorage.getItem('previous_page_url') : location.pathname,
      language: lang
    }

    setLocatorPageViewEvent(dlData, consentAccepted)
  }, [pageContent, consentAccepted])


  // added bazaarvoice script
  useEffect(() => {

    const script = document.createElement("script")
    script.src = "https://apps.bazaarvoice.com/deployments/whiteclawzero/main_site/production/en_US/bv.js"
    script.async = true
    document.head.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.head.removeChild(script)
    }

  }, [])

  // bazaarvoice verify schema
  const schema = {
    "@context": "https://schema.org",
    "@type": "Product",
    "@id": `${location.href}`,
    "name": `${pageData.product_name.text}`,
    "image": [`${pageData.product_image.url}`],
    "description": `${pageData.product_description.richText[0]?.text || ''}`,
    "brand": {
      "@type": "WhiteClaw 0%", // brand name
      "name": "WhiteClaw 0%", // range name
      "productID": `${productIdBV}`,
      "sku": `${productIdBV}`
    },
    "availability": "https://schema.org/InStock ",
    "Url": `${location.href}`
  }

  const schemaAsString = JSON.stringify(schema, null, 2);

  // get full address from zipcode using google map API
  const getAddressFromZipCode = async (zipcode) => {
    try {
      const response = await axios({
        url: GOOGLE_MAP_API_URL('AIzaSyB14cC5KRuDXStxieEY6zbN_NdxKLLMaeg', zipcode),
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })

      if (response.status === 200) {
        const result = response.data.results[0]
        if (!result) {
          throw new Error('No address found.')
        }

        return result.address_components.reduce((cus, component) => {
          const types = component.types

          if (types.includes('postal_code')) {
            cus.zc = component.long_name
          } else if (types.includes('locality')) {
            cus.cty = component.long_name
          } else if (types.includes('country')) {
            cus.ctry = component.short_name
          } else if (types.includes('administrative_area_level_1')) {
            cus.st = component.long_name
          }
          return cus
        }, {addr: result.formatted_address})
      } else {
        throw new Error(`Error: ${response.status}, ${response.statusText}`)
      }
    } catch (err) {
      console.error(err.message)
      return null
    }
  }

  const setConversionData = (conversionData) => {
    window.BV.pixel.trackConversion(conversionData);
  }

  return (
      <Layout currentPage='product-details' activeDocMeta={activeDoc} setPreviousPage={false}>
        <Seo
            title={pageData.meta_title?.text}
            description={pageData.meta_description?.text}
            image={pageData.social_card?.url}
            activeDocMeta={activeDoc}
        >
          <script type="application/ld+json">{schemaAsString}</script>
        </Seo>
        <section className="inside-content product-details">
          <div className="container-fluid inside-content-row py-0">
            <div className="row">
              <div className="col-lg-6 p-0">
                <div
                    className={`product-image${productType.can ? ' product-image--can' : ''} ${productType.pack ? ' product-image--pack' : ''}`}>
                  <ImgWithFallback
                      classNamePicture={"product-image__bg-pct"}
                      classNameImg={"product-image__bg-img"}
                      src={pageData.product_bg.url}
                      fallbackSrc={pageData.product_bg.url}
                      alt={pageData.product_bg.alt}
                  />
                </div>
              </div>
              <div className="col-lg-6 p-0">
                <div className="product-content">
                  <h1 className="product-name-title" style={{color: pageData.product_name_color}}>
                    <PrismicRichText field={productName}/>
                  </h1>
                  <div data-bv-show="rating_summary"
                       data-bv-product-id={productIdBV}
                       className='pdp-rating-summary'
                  >
                  </div>
                  <div className='d-flex product-buttons-wrap'>
                    <a onClick={() => {
                      scroller.scrollTo(`locator-anchor`, {
                        duration: 1000,
                        delay: 50,
                        smooth: true,
                      });

                      // data layer - buy now clicked event
                      let category = pageContent.data.product_category.document.data.category_name.toLowerCase() || ''
                      category = (category === 'original') ? 'original non-alcoholic' : category
                      const dl = {
                        flavor: pageContent.data.taxonomy_product_flavor,
                        category: category
                      }

                      setBuyNowClickedEvent(dl, consentAccepted)
                      setConversionData({
                        "type": "WhereToBuy"
                      })
                    }
                    }
                       className="product-action-btn"> {productLocatorBtnText}
                    </a>

                    <a href={productCtaBtnLink} className="product-action-btn">
                      {productCtaBtnText}
                    </a>
                  </div>


                  <p className="product-description">
                    <PrismicRichText field={productDescription}/>
                  </p>

                  {/* mikmak locator */}
                  <Locator/>

                  <div className="product-info mb-5">
                    {rtbItemsList.map(({product_info_icon, product_info_name}, index) => {
                      return (
                          <div className="product-info__item" key={index}>
                            <img className="product-info__icon" src={product_info_icon.url}
                                 alt={product_info_icon.alt}/>
                            <div className="product-info__name">{product_info_name.text}</div>
                          </div>
                      );
                    })}
                  </div>

                  {categoryInfoSubTitle.length !== 0 &&
                      <div className='product-info__subtitle'>
                        <PrismicRichText field={categoryInfoSubTitle}/>
                      </div>
                  }

                  {isNutritionalTableSlice &&
                      <section className="product-nutrition">
                        <h2>
                          <button className="product-nutrition__toggle" onClick={ToggleTableClass}>
                            {pageData.table_title}
                            <FontAwesomeIcon
                                icon={faChevronDown}
                                className="fa-solid"
                                size="lg"
                                flip={isTableActive ? "vertical" : false}
                                aria-hidden="false"/>
                          </button>
                        </h2>

                        <table id="product-nutrition-table"
                               className={`product-nutrition__table${isTableActive ? " is-open" : ""}`}
                               data-serving-size-selected>
                          <tbody>
                          <tr className="product-nutrition__row product-nutrition__row--dark-underline">
                            <td className="product-nutrition__row-left product-nutrition__serving-title">{pageData.serving_size_title}</td>
                            <td className="product-nutrition__row-right">
                              <div role="button"
                                   className="product-nutrition__serving-toggle d-flex justify-content-end align-items-center"
                                   onClick={ToggleServingClass}>
                                <div id="product-nutrition-selected" className="product-nutrition__serving-selected"
                                     aria-selected="true">
                                  {userSelection}
                                </div>
                                {dropdownSizes.length !== 1 && <FontAwesomeIcon
                                    icon={faChevronDown}
                                    className="fa-solid ml-1"
                                    size="sm"
                                    flip={isServingActive ? "vertical" : false}
                                    aria-hidden="false"/>}
                                <div role="menu" aria-expanded="true"
                                     className={`product-nutrition__serving-menu${isServingActive ? " is-open" : ""}`}>
                                  {dropdownSizes.map((element, index) => {
                                    if (dropdownSizes.length !== 1) {
                                      return (
                                          <div
                                              role="menuitem"
                                              key={index}
                                              className="product-nutrition__serving-item"
                                          >
                                            {element.size}
                                          </div>
                                      );
                                    }
                                  })}
                                </div>
                              </div>
                            </td>
                          </tr>
                          </tbody>

                          {selectedNutritionSlice[0]?.items.length !== 0 &&
                              <SliceZone slices={selectedNutritionSlice} components={pdpComponents}/>
                          }
                        </table>
                      </section>
                  }

                  <section className="product-quantities">
                    {pageData.product_sizes.map((element, index) => {
                      return (
                          <div key={index} className="product-quantities__row product-quantities__row--first-sizes">
                            <div className="product-quantities__col">
                              {index === 0 &&
                                  <h2 className="product-quantities__title">{pageData.product_sizes_title}</h2>}
                            </div>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element.product_size_icon.url} alt={element.product_size_icon.alt}
                                   className="product-quantities__icon product-quantities__icon--sizes"/>
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element.product_size}</div>
                            </div>
                          </div>
                      );
                    })}

                    {pageData.product_packs.map((element, index) => {
                      return (
                          <div key={index} className="product-quantities__row product-quantities__row--first-packs">
                            <div className="product-quantities__col">
                              {index === 0 &&
                                  <h2 className="product-quantities__title">{pageData.product_packs_title}</h2>}
                            </div>
                            <div className="product-quantities__col product-quantities__col--icon">
                              <img src={element.product_pack_icon.url} alt={element.product_pack_icon.alt}
                                   className="product-quantities__icon product-quantities__icon--packs"/>
                            </div>
                            <div className="product-quantities__col">
                              <div className="product-quantities__text">{element.product_pack}</div>
                            </div>
                          </div>
                      );
                    })}
                  </section>
                  <SliceZone slices={allFlavorsSlice} components={pdpComponents}/>
                  <SliceZone slices={allFlavorsVPSlice} components={pdpComponents}/>
                </div>

              </div>
            </div>
          </div>
          <Reviews productId={productIdBV}/>
          <SliceZone slices={filteredSlices} components={pdpComponents}/>
        </section>
      </Layout>
  );
}

export const query = graphql`
  query ProductDetailsPageQuery($uid: String, $id: String, $lang: String) {
    prismicProductDetailsPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        cta_button_link{
          url
        }
        cta_button_text{
          text
        }
        social_card {
          url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        product_name {
          text
          richText
        }
        product_id
        product_name_color
        product_type
        product_new_text
        product_category {
          document {
            ... on PrismicProductCategory {
              id
              data {
                category_name_prefix
                category_name
                taxonomy_product_category
                category_name_suffix
                category_new_text
                category_info {
                  product_info_icon {
                    url
                    alt
                  }
                  product_info_name {
                    text
                    richText
                  }
                }
                category_info_subtitle {
                  richText
                }
                category_item {
                  category_item_link {
                    document {
                      ... on PrismicProductDetailsPage {
                        id
                        url
                        data {
                          product_name {
                            text
                            richText
                          }
                          product_image {
                            url
                            alt
                          }
                          product_id
                          product_type
                          product_new_text
                          taxonomy_product_size
                          taxonomy_product_flavor
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        product_image {
          url
          alt
        }
        product_bg {
          url
          alt
        }
        product_action_title
        product_action_link {
          url
        }
        product_description {
          richText
        }
        product_info {
          product_info_icon {
            url
            alt
          }
          product_info_name{
            richText
            text
          }
        }
        product_sizes_title
        product_sizes {
          product_size
          product_size_icon {
            url
            alt
          }
        }
        product_packs_title
        product_packs {
          product_pack
          product_pack_icon {
            url
            alt
          }
        }
        table_title
        serving_size_title
        serving_size_list {
          sizes
        }
        taxonomy_product_size
        taxonomy_product_flavor
        body {
          ... on PrismicProductDetailsPageDataBodyNutritionAndIngredientsTable {
            id
            items {
              calories
              carbohydrate_g
              carbohydrate_percentage
              cholesterol_mg
              fat_g
              fat_percentage
              fibre_g
              fibre_percentage
              daily_value {
                richText
                text
              }
              daily_value_description{
                richText
                text
              }
              ingredients {
                richText
                text
              }
              page_lang
              product_size
              protein_g
              protein_percentage
              saturated_g
              saturated_percentage
              polyunsaturated_g
              polyunsaturated_percentage
              monounsaturated_g
              monounsaturated_percentage
              sodium_mg
              sodium_percentage
              sugars_g
              sugars_percentage
            }
            slice_type
            slice_label
          }
          ... on PrismicProductDetailsPageDataBodyAllFlavorsVp {
            id
            slice_type
            slice_label
            items {
              item_link {
                document {
                  ... on PrismicProductDetailsPage {
                    id
                    url
                    data {
                      product_id
                      product_new_text
                      product_name {
                        text
                        richText
                      }
                      product_image {
                        url
                        alt
                      }
                      taxonomy_product_flavor
                      taxonomy_product_size
                      product_category {
                        document {
                          ... on PrismicProductCategory {
                            id
                            data {
                              category_new_text
                              category_name
                              taxonomy_product_category
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            primary {
              header {
                text
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyAllFlavors {
            id
            slice_type
            slice_label
            primary {
              header {
               text
              }
              category {
                document {
                  ... on PrismicProductCategory {
                    id
                    data {
                      category_name_prefix
                      category_name
                      taxonomy_product_category
                      category_name_suffix
                      category_new_text
                      category_item {
                        category_item_link {
                          document {
                            ... on PrismicProductDetailsPage {
                              id
                              url
                              data {
                                product_id
                                product_name {
                                  text
                                  richText
                                }
                                product_image {
                                  url
                                  alt
                                }
                                product_type
                                product_new_text
                                taxonomy_product_size
                                taxonomy_product_flavor
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicProductDetailsPageDataBodyInstagramGallery {
            id
            slice_type
          }
          ... on PrismicProductDetailsPageDataBodyPromoModule {
           id
           slice_type
           slice_label
           items {
             image {
               url
               alt
             }
             button_link {
               url
             }
             button_text {
                text
             }
             subtitle {
               text
             }
           }
           primary {
             title {
               text
             }
           }
        }
          ... on PrismicProductDetailsPageDataBodyInstagramSection {
            id
            slice_type
            primary {
              title {
                richText
                text
              }
              description {
                richText
                text
              }
              copy_color
              background_color
              widget_view
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
export default withPrismicPreview(ProductDetails)