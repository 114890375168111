import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import { useLocation } from '@reach/router';

const Seo = ({ description, title, image, activeDocMeta = { alternateLanguages: null } , children  }) => {
  const location = useLocation();

  const  { site , featuredImage } = useSiteMetadata()

  const ogImage = featuredImage && featuredImage?.childImageSharp?.gatsbyImageData

  const seo = {
    siteUrl: site.siteMetadata.siteUrl,
    title: title || site.siteMetadata.title,
    description: description || site.siteMetadata.description,
    ogTitle: title || site.siteMetadata.title,
    ogDescription: description || site.siteMetadata.description,
    ogImage: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`,
    ogUrl: site.siteMetadata.siteUrl,
    ogImageSecureUrl: image || `${site.siteMetadata.siteUrl}${ogImage?.images.fallback.src}`
  }

  // Check if there are pages with an alternative language
  const hasAlterLangPage = activeDocMeta.alternateLanguages?.length
  const href = {
    en: location.pathname.includes('/es') ? location.pathname.replace('/es','') : location.pathname,
    es: location.pathname.includes('/es') ?  location.pathname : location.pathname.replace('/','/es/')
  }

  const testPages = ['qr-packaging', 'qr-comms']

  const isTestPage = testPages.some((page) => {
    return location.pathname === `/${page}`
  })

  const isProd = !location.href?.includes('dev.') && !location.href?.includes('staging.')

  const isNoIndexNoFollow = !isProd || isTestPage

  return (
    <Helmet>
      {/* page lang tag to html */}
      <html lang="en-US" prefix="og:http://ogp.me/ns#"/>

      <title>{ seo.title }</title>
      <meta name="description" content={seo.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />

      {/* Referrer Policies */}
      <meta name="referrer" content="strict-origin-when-cross-origin"/>

      {/* robots noindex, nofollow for dev and staging */}
      { isNoIndexNoFollow && <meta name="robots" content="noindex, nofollow"/>}

      {/* Hreflang tags */}
      { hasAlterLangPage && <link rel="alternate" hrefLang="en-US" href={`${seo.siteUrl + href.en}`}/> }
      { hasAlterLangPage && <link rel="alternate" hrefLang="es-US" href={`${seo.siteUrl + href.es}`}/> }

      { /*Open Graph*/ }
      <meta property="og:title" content={seo.ogTitle} />
      <meta property="og:description" content={seo.ogDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${seo.siteUrl}${location.pathname}`} />

      {seo.ogImage && <meta property="og:image" itemProp="image" content={seo.ogImage} />}
      {seo.ogImage && <meta property="og:image:secure_url" content={seo.ogImage} />}
      {seo.ogImage && <meta property="og:image:width" content={image  ? "1200" : seo.ogImage.width} />}
      {seo.ogImage && <meta property="og:image:height" content={image  ? "630" : seo.ogImage.height} />}

      { children }
    </Helmet>
  )
}

export default Seo