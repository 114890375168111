import React from 'react'
import NewsLetterSignUp from '../components/NewsLetterSignUp'
import { ACTIVE_CAMPAIGN_TAG, SUBSCRIPTION_FORM } from '../constants'

export const SubscribeForm = ({ slice }) => {
  const formTitle = 'BE THE FIRST TO KNOW'
  const subTitle = 'Sign up to be first to hear about when White Claw 0% Alcohol drops and where to find it.'
  const submitBtnText = 'SIGN UP NOW'

  //TODO: create field in slice
  const formName = SUBSCRIPTION_FORM.FOOTER
  const formTag = ACTIVE_CAMPAIGN_TAG.FOOTER

  return (
      <section className='subscribe-form-slice'>
          <NewsLetterSignUp subscriptionFormTitle={formTitle} subscriptionFormCopy={subTitle} subscriptionFormName={formName} submitBtnText={submitBtnText} activeCampaignTag={formTag}/>
      </section>
  )
}