// core
import { PrismicRichText } from "@prismicio/react";
import React from "react";

export const HalfTextHalfImage = ({ slice }) => {
  // console.log("slice data ", slice);

  const getImageAlignment = (alignment) => {
    switch (alignment) {
      case 'left':
        return { top: '50%', left: '0', transform: 'translateY(-50%)' };
      case 'right':
        return { top: '50%', right: '0', transform: 'translateY(-50%)' };
      case 'top':
        return { top: '0', left: '50%', transform: 'translateX(-50%)' };
      case 'bottom':
        return { bottom: '0', left: '50%', transform: 'translateX(-50%)' };
      case 'center':
      default:
        return { top: '50%', left: '50%', transform: 'translate(-50%, -50%)' };
    }
  }

  return (
    slice.items.map((sliceItem, sliceIndex) => {
      const textBgImageURL = sliceItem?.text_bg_image?.url
      const textBgImageAlt = sliceItem.text_bg_image || ""
      const textBgImgAlign = sliceItem.text_bg_image_align.toLowerCase() || "left"
      const textOnRight = sliceItem?.text_on_right
      const imageOnRightURL = sliceItem.opposite_large_image.url || ""
      const imageOnRightAlt = sliceItem.opposite_large_image.alt || ""
      const titleText = sliceItem.title_text.richText || ""
      const bodyText = sliceItem.body_text.richText || ""
      const actionButtonText = sliceItem?.action_button_text

      return (
        <section
          className={`half-text-half-image${textOnRight ? ' half-text-half-image--text-on-right' : ''}`}
          key={sliceIndex}
        >
          <div className="half-text-half-image__text">
            <div className="half-text-half-image__text-content">
              <h3><PrismicRichText field={titleText} /></h3>
              <p><PrismicRichText field={bodyText} /></p>
              {actionButtonText && <button className="half-text-half-image__action-button">{actionButtonText}</button>}
            </div>
            {textBgImageURL && <div className="half-text-half-image__text-bg">
              <img 
                src={textBgImageURL} 
                alt={textBgImageAlt}
                style={getImageAlignment(textBgImgAlign)}
              />
            </div>}
          </div>
          <div className="half-text-half-image__image">
            <img className="half-text-half-image__image-src" src={imageOnRightURL} alt={imageOnRightAlt} />
          </div>
        </section>
      )
    })
  );
};
