/**
 * This file contains configuration for `gatsby-plugin-prismic-previews` to
 * support preview sessions from Prismic with drafts and unpublished documents.
 *
 * @see https://prismic.io/docs/technologies/previews-gatsby
 */

import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from './linkResolver'

import Homepage from '../templates/index.js';
import ErrorPage from '../pages/404.js'
import PrivacyPolicyPage from '../templates/privacy'
import DocumentPage from '../templates/document-page.js';
import ProductDetailsPage from '../templates/product-details';
import ProductsPage from "../templates/products.js";
import SubscribePage from "../templates/subscribe-page.js";
import CampaignPage from "../templates/campaign-page";
import ProductLocatorPage from "../templates/product-locator-page"
import GenericPage from '../templates/generic-page.js';
import ContactUsPage from '../templates/contact-us';
import DsarRequestPage from "../templates/dsar-request";
import WishpondEmbeddedPage from '../templates/wishpond-page'
/**
 * Prismic preview configuration for each repository in your app. This set of
 * configuration objects will be used with the `PrismicPreviewProvider`
 * higher order component.
 *
 * If your app needs to support multiple Prismic repositories, add each of
 * their own configuration objects here as additional elements.
 *
 */
export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPOSITORY_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      homepage: Homepage,
      error_page: ErrorPage,
      privacy_policy_page: PrivacyPolicyPage,
      document_page: DocumentPage,
      generic_page: GenericPage,
      product_details: ProductDetailsPage,
      products_page: ProductsPage,
      subscribe_page: SubscribePage,
      campaign_page: CampaignPage,
      product_locator_page: ProductLocatorPage,
      contact_us_page: ContactUsPage,
      dsar_page: DsarRequestPage,
      wishpond_page: WishpondEmbeddedPage
    }),
  },
];