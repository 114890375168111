import { HalfTextHalfImage } from "../HalfTextHalfImage";
import { FullWidthImageText } from "../FullWidthImageText";
import { FullWidthText } from "../FullWidthText";
import { ProductCarousel } from "../ProductCarousel";
import { PreformattedText } from "../PreformattedText"
import { CrossLink } from "../CrossLink";
import { Testimonials } from "../Testimonials";

export const components = {
  half_text_half_image: HalfTextHalfImage,
  full_width_image_text: FullWidthImageText,
  full_width_text: FullWidthText,
  product_carousel: ProductCarousel,
  preformatted_text: PreformattedText,
  crosslink: CrossLink,
  testimonials: Testimonials
}