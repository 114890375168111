import React from 'react'

// assets
import useWindowSize  from "../../hooks/useWindowSize";


export const CampaignRangeSection = ({ slice }) => {
  const { width } = useWindowSize()

  const titleRows = slice.primary.title.richText
  const productItems = slice.items

  const bgImageUrl = {
    mobile: slice.primary.background_image_mobile.url,
    screen: slice.primary.background_image.url
  }

  return (
      <section className='campaign-range'
               style={{backgroundImage: `${ width && width <= 992 ? `url(${bgImageUrl.mobile})` : `url(${bgImageUrl.screen})`}`}}
      >
        <div className='container campaign-range__container'>
          <div className='campaign-range__content'>
            <div className='campaign-range__title-container'>
              <h3 className='campaign-range__title pb-0'>
                {titleRows && titleRows.map((row) => {
                  return  <span key={row.text}>{row.text}<br/></span>
                }) }
              </h3>
            </div>
            <div className='campaign-range__items-container'>
              {productItems?.length && productItems.map(({product_image}) => {
                return <img
                    className='campaign-range__item'
                    src={product_image.url}
                    alt={product_image.alt}
                    key={product_image.url}
                />
              })}
            </div>
          </div>
        </div>
      </section>
  )
}