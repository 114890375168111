import React  from 'react'

const Reviews = ({productId}) => {
  return <section className='reviews'>
    <div
        data-bv-show="reviews"
        data-bv-product-id={productId}
    >
    </div>
  </section>
}

export default Reviews