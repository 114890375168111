// core
import React from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import Layout from '../components/Layout'
import Seo from "../components/Seo"
import CommonPageBanner from '../components/CommonPageBanner'
import ContactUsForm from '../components/contactPageComponents/ContactUsForm'

const ContactUsPage = ({ data }) => {
    const pageContent = data.prismicContactUsPage
    const pageData = data.prismicContactUsPage.data
    const { lang, type, url } = pageContent
    const alternateLanguages = pageContent.alternate_languages || []
    const activeDoc = {
        lang,
        type,
        url,
        alternateLanguages
    }

    return (
        <Layout currentPage="contact-us" activeDocMeta={activeDoc}>
            <Seo
                title={pageData.meta_title?.text}
                description={pageData.meta_description?.text}
                image={pageData.social_card?.url}
                activeDocMeta={activeDoc}
            />
            <div className="inside-content contact-us p-0">
                <CommonPageBanner
                    title={pageData.banner_title.text}
                    backgroundUrl={pageData.banner_background.url}
                    subtitle={pageData.banner_subtitle.text}
                    description={pageData.banner_description.html}
                />
                <div className="container py-5 mt-3">
                    <div className="row">
                        <div className="col-md-12">

                            <h1 className="mb-3">{pageData.content_header.text}</h1>
                            { lang === 'en-us'&& 
                              <p className="mb-3" role="heading" aria-level="6">
                                Information submitted is subject to our <Link to="/privacy" target='_blank'><u>Privacy Policy</u></Link>.
                              </p>
                            }

                            <ContactUsForm activeDocMeta={activeDoc} />
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const query = graphql`
  query ContactUsPageQuery($uid: String, $id: String, $lang: String){
    prismicContactUsPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        banner_background {
          alt
          url
        }
        banner_description {
          text
        }
        banner_subtitle {
          text
        }
        banner_title {
          text
        }
        content_header {
          text
        }
        social_card{
        url
        }
        meta_description {
          text
        }
        meta_title {
          text
        }
        page_content {
          html
          text
        }
      }
    }
  }
`

export default withPrismicPreview(ContactUsPage)