// core
import React, {useState, useEffect} from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import Modal from 'react-bootstrap/Modal'

// assets
import globe from '../assets/images/icons/globe.svg'

// Resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

// preview
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews"

const CountrySelector = ({ showCountrySelector, closeCountrySelector, activeDocMeta }) => {

  const queryData = useStaticQuery(graphql`
  {
    prismicCountrySelector(_previewable: {}, id: {}) {
      data {
        body {
          ... on PrismicCountrySelectorDataBodyCountryName {
            id
            slice_label
            primary {
              country_group_name
            }
            items {
              countrylanguageid
              countryname
              language
              langtag
              url
              suggestedcountries
              flag {
                alt
                url
              }
            }
            slice_type
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const [siteLanguageId, setSiteLanguageId] = useState("")
  const northAmerica = data.prismicCountrySelector?.data.body

  useEffect(() => {
    // Update this when we have site lang is available
    // Adding active class to ".country-wrapper"
    // Get Current Site Lang
    setSiteLanguageId(activeDocMeta === "en-us" ? "1" : "2")
  },[activeDocMeta])

  return (
      <>
        <Modal
            show={showCountrySelector}
            onHide={closeCountrySelector}
            animation={false}
            className="country-selector-modal"
        >

          <Modal.Header>
            <FontAwesomeIcon
                icon={faTimes}

                data-dismiss="modal"
                aria-label="Close"
                onClick={closeCountrySelector}
            />
          </Modal.Header>

          <Modal.Body>

            {/* Suggested Countries */}
            <div className="suggested-countries mb-3">
              <div className="suggested-countries__tlt">
                <h4 className="mb-1">
                  <img src={globe} alt="Globe Icon" />
                  Suggested country and language
                </h4>
              </div>
              <div className="suggested-countries__list row ml-1 mr-0">
                {
                  northAmerica?.map((elm, index) => { // eslint-disable-line array-callback-return
                    if (index <= 3) {
                      return (
                          elm.items.map((elm) => {
                            return (
                                <Link
                                    to={elm.url}
                                    target="_self"
                                    title={elm.countryname}
                                    key={elm.url}
                                >
                                  <div className=
                                           {
                                               "country-wrapper d-flex " +
                                               (
                                                   siteLanguageId === elm.countrylanguageid
                                                       ? "active" : null
                                               )
                                           }
                                  >
                                    <div className="country__flag"
                                         style={(elm.flag.url
                                                 ?
                                                 {backgroundImage: `url(${elm.flag.url})`}
                                                 :
                                                 null
                                         )}
                                    >
                                    </div>
                                    <div className="country__item">
                                      <span>{elm.countryname}</span>
                                      <br/>
                                      <span>{elm.language}</span>
                                    </div>
                                  </div>
                                </Link>
                            )
                          })
                      )
                    }
                  })
                }
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
  )
}

export default CountrySelector