// core
import React, { useState, useEffect } from 'react'
import axios from 'axios'

// form validation
import { Formik, Form, Field } from "formik"

// assets
import { validateDobField } from "../../assets/js";

// components
import HiddenFields from "../contactPageComponents/HiddenFields";
import SuccessFailModal from "../contactPageComponents/SuccessFailModal";
import { useConsent } from "../ConsentContext";

// Resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'


// constants
import { BASE_URL, LANG} from "../../constants";
import { VALIDATION_EN, VALIDATION_ES } from "../../constants/formValidation";

import { DSARFormSchema } from '../../schemas'


const DsarForm = ({ activeDocMeta }) => {
  const { lang } = activeDocMeta ? activeDocMeta : LANG.EN
  const validations = lang === LANG.EN ? VALIDATION_EN : VALIDATION_ES
  const consentAccepted = useConsent()

  const [segmentID, setSegmentID] = useState("")

  // show success fail modal functions
  const [userName, setUserName] = useState('')
  const [modalBodyCopy, setModalBodyCopy] = useState("")
  const [showSuccessFailModal, setShowSuccessFailModal] = useState(false)


  const initialValues = {
    email: "",
    inquiryTypeID: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    message: "",
    batchNumber: "",
    usaStatesList: "",
    extraFieldsCheckbox: false,
    packageType: "",
    packageQuantity: "",
    flavorName: ""
  }


  const closeSuccessFailModal = () => {
    setShowSuccessFailModal(false)
  }


  useEffect(() => {

    const waitWindowAnalyticsObj = () => {
      if (typeof window !== "undefined") {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let analyticsLocalObj = window.analytics
        if (analyticsLocalObj && consentAccepted) {

          // prevent page down in incognito mood when clicking on "Confirm My Choices" on OneTrust
          const  anonymous_id = analyticsLocalObj.hasOwnProperty('user')
              ? analyticsLocalObj.user().anonymousId() : analyticsLocalObj.group().hasOwnProperty('anonymousId')
                  ? analyticsLocalObj.group().anonymousId() : ''

          setSegmentID(anonymous_id)
        } else {
          setTimeout(waitWindowAnalyticsObj, 250)
        }
      }
    }
    waitWindowAnalyticsObj()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consentAccepted])



  let isSubmitBlocked = false
  return (

      <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={DSARFormSchema(validations)}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let extraDetails = [];

            // Add segmentId to extraDetails[]
            extraDetails.push({ KeyName: 'segmentId', Value: segmentID });

            let data = {
              ApplicationID: 1046,
              Email: values.email.trim(),
              InquiryTypeID: values.inquiryTypeID,
              FirstName: values.firstName.trim(),
              LastName: values.lastName.trim(),
              BirthDate: values.birthDate.trim(),
              Message: values.message.trim(),
              ExtraDetails: extraDetails
            }


            if(!isSubmitBlocked) {
              isSubmitBlocked = true

              axios({
                method: "POST",
                url: BASE_URL.PROD,
                data: JSON.stringify(data),
                cache: false,
                headers: {
                  "Content-Type": "application/json; charset=utf-8"
                }
              })
                  .then(function (response) {
                    isSubmitBlocked = false


                    // Set data for success fail modal
                    setUserName(values.firstName.trim())
                    setModalBodyCopy(validations.messageYes)
                    setShowSuccessFailModal(true)
                    // Reset form
                    resetForm()
                  })
                  .catch(function () {
                    isSubmitBlocked = false
                    // Set data for success fail modal
                    setUserName(values.firstName.trim())
                    setModalBodyCopy(validations.messageNo)
                    setShowSuccessFailModal(true)

                    // Reset form
                    resetForm()
                  })
              setSubmitting(false)
            }
          }}
      >

        {({ values, errors, submitCount, handleChange, setFieldValue }) => {

          return (
              <Form id="frm-contact-form" autoComplete="off">
                <div className="row">
                  <div className="col-md-6 form-group control-group">
                    {/* Email */}
                    <Field type="email" id="form-email" className="form-control" name="email" placeholder={validations.email} />

                    { submitCount > 0 && errors.email && <span className="error-message"> {errors.email} </span> }
                  </div>

                  <div className="col-md-6 form-group">
                    <label className="select">
                      {/* Inquiry Type */}
                      <Field
                          as="select"
                          className="form-control"
                          name="inquiryTypeID"
                          onChange={(e) => {
                            const value = e.target.value;
                            setFieldValue("inquiryTypeID", value);
                          }}
                      >
                        <option value="">{validations.pleaseSelectTopic}</option>

                        <option value="100000023">
                          {validations.dontSellInfo}
                        </option>
                        <option value="100000024">
                          {validations.deleteMyPersonalInfo}
                        </option>
                        <option value="100000025">
                          {validations.accessMyPersonalInfo}
                        </option>
                        <option value="100000026">
                          {validations.correctMyPersonalInfo}
                        </option>
                        <option value="100000027" >
                          {validations.transferMyPersonalInfo}
                        </option>
                      </Field>

                      <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />

                      { submitCount > 0 && errors.inquiryTypeID && <span className="error-message"> {errors.inquiryTypeID} </span> }

                    </label>
                  </div>
                </div>

                {/*/!* Hidden Fields Component *!/*/}
                <HiddenFields inquiryTypeID={ values.inquiryTypeID } lang={lang} validations={validations} values={values}/>

                <div className="row">
                  <div className="col-md-4 form-group">
                    {/* First Name */}
                    <Field type="text" id="form-firstname" className="form-control" name="firstName" placeholder={validations.firstNameDD} />

                    { submitCount > 0 && errors.firstName && <span className="error-message"> {errors.firstName} </span> }
                  </div>

                  <div className="col-md-4 form-group">
                    {/* Last Name */}
                    <Field type="text" id="form-lastname" className="form-control" name="lastName" placeholder={validations.lastNameDD} />

                    { submitCount > 0 && errors.lastName && <span className="error-message"> {errors.lastName} </span> }
                  </div>

                  <div className="col-md-4 form-group">
                    {/* Birthday */}
                    <Field
                        type="text"
                        id="form-birthdate"
                        className="form-control"
                        name="birthDate"
                        placeholder={validations.birthDateDD}
                        onChange={(e) => {
                          const output = validateDobField(e)
                          setFieldValue("birthDate", output.join('').substr(0, 10));
                        }}
                    />


                    { submitCount > 0 && errors.birthDate && <span className="error-message"> {errors.birthDate} </span> }
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 form-group form-text-field">
                    {/* Message */}
                    <Field as="textarea" id="form-message" className="form-control" name="message" rows="5" type="text" placeholder={validations.msgDD} />

                    { submitCount > 0 && errors.message && <span className="error-message"> {errors.message} </span> }
                  </div>


                  <div className={`col-12 form-group form-text-field form-btn-container d-flex justify-content-end`}>
                    {/* Submit Btn */}
                    <button className="form-btn" type="submit" id="submit-contact-form">
                      {validations.submitBtn}
                    </button>
                  </div>
                </div>
                {/* Success Fail Component */}
                <SuccessFailModal showSuccessFailModal={showSuccessFailModal} closeSuccessFailModal={closeSuccessFailModal} userName={userName} modalBodyCopy={modalBodyCopy} activeLang={lang}/>
              </Form>
          );
        }}
      </Formik>
  )
}

export default DsarForm



