import React, {useEffect, useRef, useState} from 'react'
import IframeResizer from "iframe-resizer-react";

import { setSocialPostClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

export const InstagramGallery = ({ slice }) => {
  const [tileIndex, setTileIndex] = useState(null)
  const ref = useRef()
  const consentAccepted = useConsent()

  const onMessage = data => {
    setTileIndex(data.message)
  }

  useEffect(()=>{
    if(tileIndex) {
      setSocialPostClickedEvent({position: tileIndex}, consentAccepted)
    }
  },[tileIndex])

  return (
      <section id='instagram-section'>
        {/* bazaarvoice instagram widget  */}
        <IframeResizer
            forwardRef={ref}
            onMessage={onMessage}
            heightCalculationMethod="lowestElement"
            src="/iframes/bv-soco-widget.html"
            id='bv-soco'
        />
      </section>
  );
}