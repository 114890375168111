// core
import React, { useState, useEffect, useRef } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

// components
import CountrySelector from './CountrySelector'

// images and icons
import whiteClawLogo from '../assets/images/gold-logo-horizontal.webp'
import instagramIcon from "../assets/images/icons/instagram.svg";

// preview
import { useMergePrismicPreviewData } from 'gatsby-plugin-prismic-previews'

// constants
import { SOCIAL_PLATFORM_URL, SOCIAL_PLATFORM } from "../constants";

// data-layer
import { setSocialIconClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

const Header = ({disabledMenu, customPage, activeDocMeta}) => {
  // console.log(activeDocMeta)
  const { lang } = activeDocMeta ? activeDocMeta : { lang: "en-us" }

  const queryData = useStaticQuery(graphql`
  {
    EN: prismicNavigation(lang: { eq: "en-us" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
            }
            primary {
              link_text
            }
          }
        }
      }
    }
    ES: prismicNavigation(lang: { eq: "es-mx" }) {
      id
      _previewable
      data {
        body {
          ... on PrismicNavigationDataBody1stLevel {
            slice_type
            primary {
              link_text
              nav_link {
                url
                slug
                id
                link_type
              }
            }
          }
          ... on PrismicNavigationDataBody2ndLevel {
            slice_type
            items {
              third_level_link_text
              third_level_link {
                slug
                url
                id
                link_type
              }
            }
            primary {
              link_text
            }
          }
        }
      }
    }
  }
  `)

  const { data } = useMergePrismicPreviewData(queryData)
  const pageData = lang === 'en-us' ?  data.EN.data :  data.ES.data
  const navigation = pageData.body
  const [showCollapsedNavbar, setShowCollapsedNavbar] = useState(false)
  const [scrollNavbar, setScrollNavbar] = useState(false)
  const [activeDropdownMenu, setActiveDropdownMenu] = useState(false);
  const dropdownRef = useRef(null);
  const consentAccepted = useConsent()

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)


  // console.log('pageData', pageData)

  const onSocialIconClick = (platform) => {
    const dl = {
      platform,
      location: 'header'
    }
    setSocialIconClickedEvent(dl, consentAccepted)
  }

  // scroll func
  const handleScroll = () => {
    window.scrollY < 90 ? setScrollNavbar(false) : setScrollNavbar(true)
    setActiveDropdownMenu(null)
  }

  const handleSecondLevelMenuClick = (itemID) => {
    setActiveDropdownMenu(itemID)
  }

  const handleCloseOnWindowClick = (e) => {
    if (dropdownRef?.current && !dropdownRef?.current.contains(e.target)) {
      setActiveDropdownMenu(null)
    }
  }

  const handleWindowBlur = () => {
    setActiveDropdownMenu(null)
  }

  useEffect(() => {
    if(!showCollapsedNavbar) {
      window.addEventListener("click", handleCloseOnWindowClick)
      window.addEventListener("blur", handleWindowBlur)
    } else {
      window.removeEventListener("click", handleCloseOnWindowClick)
      window.removeEventListener("blur", handleWindowBlur)
    }
    return () => {
      window.removeEventListener("click", handleCloseOnWindowClick)
      window.removeEventListener("blur", handleWindowBlur)
    }
  }, [showCollapsedNavbar])

  useEffect(() => {
    // add event listener
    window.addEventListener("scroll", handleScroll)
    document.body.classList.remove('overflow-hidden')
    document.documentElement.className = ''
    return () => window.removeEventListener("scroll", handleScroll)
  }, [scrollNavbar])

  let headerClass = (scrollNavbar) ? ' scrolled' : ''

  // render header menus
  return (
      <>
        <header className={(customPage)
            ? "header d-flex align-items-center header--" + customPage + headerClass
            : "header d-flex align-items-center" + headerClass
        }>

          <div className="container-fluid">
            <nav className="navbar-expand-lg navbar" role="navigation">
              <div className="header__logo">
                <Link to={lang === 'en-us' ? '/' : '/es/'} target="_self" title="White Claw">
                  <img src={whiteClawLogo} alt="White Claw 0% alcohol Logo" className='header__logo-img' loading="eager" />
                </Link>
              </div>

              {(disabledMenu)
                  ? [] :
                  <div className={(showCollapsedNavbar)
                      ? 'collapse navbar-collapse d-lg-flex justify-content-lg-end show'
                      : 'collapse navbar-collapse d-lg-flex justify-content-lg-end'}
                       id="navbarNav"
                  >

                    <ul className="navbar-nav" ref={dropdownRef}>
                      {
                        navigation.map((element, index) => { // eslint-disable-line array-callback-return
                          const linkUrl = element.primary.nav_link.url || '/'
                          const linkText = element.primary.link_text || ''
                          if (element.slice_type === "1st_level") {
                            return (
                                <li className="nav-item text-center" key={index}>

                                  {element.primary.nav_link.link_type === "Document" && linkUrl !== "/locator" && <Link
                                      to={linkUrl}
                                      title={"White Claw " + (linkText)}
                                  >
                                    {linkText}
                                  </Link>}

                                  {element.primary.nav_link.link_type === "Document" && linkUrl === "/locator" && <a
                                      href={linkUrl}
                                      title={"White Claw " + (linkText)}
                                      onClick={() => {
                                        sessionStorage.setItem("buy_now_clicked", "header")
                                      }}
                                  >
                                    {linkText}
                                  </a>}

                                </li>
                            )
                          } else if (element.slice_type === "2nd_level") { // Secondary level will be added here fix needed.
                            return (
                                <li className="nav-item text-center" key={index}>
                                  <button className="nav-item__dd"
                                          onClick={(e) => handleSecondLevelMenuClick(index)}
                                  >
                                    <span className="nav-item__dd-trigger active">{linkText}</span>
                                    {activeDropdownMenu && activeDropdownMenu === index &&
                                        <ul className="dropdown-menus">
                                          {
                                            pageData.body[activeDropdownMenu]?.items.map((childElm, childElmIndex) => {
                                              return (
                                                  <li key={'child' + childElmIndex} className='nav-item__second_level'>
                                                    <Link
                                                        to={childElm.third_level_link.url}
                                                        target="_self"
                                                        title="White Claw"
                                                        onClick={() => setShowCollapsedNavbar(false)}
                                                    >
                                                      {childElm.third_level_link_text}
                                                    </Link>
                                                  </li>
                                              )
                                            })
                                          }
                                        </ul>
                                    }
                                  </button>
                                </li>
                            )
                          }
                        })
                      }
                    </ul>

                    {/* Country Selector */}
                    {/*<div className="country-selector-btn-wrapper">*/}
                    {/*  <button className="country-selector-btn" onClick={openCountrySelector} onKeyDown={openCountrySelector}>*/}
                    {/*    <img src={globeWhite} alt="Globe Icon" />*/}
                    {/*  </button>*/}
                    {/*</div>*/}

                    {/* socials */}
                    <ul className="d-flex flex-row justify-content-center navbar-nav navbar-nav--social d-block">
                      <li className="nav-item text-center">
                        <a href={ SOCIAL_PLATFORM_URL.INSTAGRAM } target="_blank" rel='noopener noreferrer' className='social-icon'
                          onClick={()=>onSocialIconClick(SOCIAL_PLATFORM.INSTAGRAM)}
                        >
                          <img src={instagramIcon} alt="Instagram Icon" width='31px'/>
                        </a>
                      </li>
                    </ul>
                    {/*<CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />*/}
                  </div>
              }


              {/* Menu Hamburger */}
              {(disabledMenu)
                ? [] :
                <button className={(showCollapsedNavbar)
                  ? "navbar-toggler show"
                  : "navbar-toggler"
                } onClick={() => {
                  //collapse navbar
                  if (showCollapsedNavbar) {
                    //body not scrollable to scrollable
                    document.body.classList.remove('overflow-hidden')
                    document.documentElement.className = ''
                  } else {
                    //body scrollable to not scrollable
                    document.body.classList.add('overflow-hidden')
                    document.documentElement.className = 'overflow-hidden'
                  }
                  setShowCollapsedNavbar(!showCollapsedNavbar)
                }}>
                  <div className="hamburger vertical-center">
                    <div className="bar bar--1"></div>
                    <div className="bar bar--2"></div>
                    <div className="bar bar--3"></div>
                  </div>
                </button>
              }
            </nav>
          </div>
        </header>
      </>
  )
}

export default Header