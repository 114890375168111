import React from 'react'
import parse from "html-react-parser";

export const PreformattedText = ({ slice }) => {
  const data = slice.primary.content.text

  return (
      <section className='preformatted-text'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {parse(data)}
            </div>
          </div>
        </div>
      </section>
  )
}