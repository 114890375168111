import React, { useEffect, useRef } from 'react'
import { setSocialPostClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

export const InstagramSection = ({ slice }) => {
  // TODO: we have only 2rows WC 00 ids
  const oneRowElfsightWidgetClass = 'elfsight-app-9d2b33ab-e168-4a75-81c2-60f84f3af308';
  const twoRowsElfsightWidgetClass = 'elfsight-app-9d2b33ab-e168-4a75-81c2-60f84f3af308';
  const defaultNumberOfRows = '2 rows';
  const instagramContainer = useRef()

  const sectionTitle = slice.primary.title.text || ''
  const sectionDescription = slice.primary.description.text || ''
  const sectionBgColor = slice.primary.background_color || '#ffffff'
  const sectionTextColor = slice.primary.copy_color  || '#000000'
  const containerClassName = slice.primary.widget_view === defaultNumberOfRows ? twoRowsElfsightWidgetClass : oneRowElfsightWidgetClass
  const consentAccepted = useConsent()

  useEffect(() => {

    //append socials script
    const script = document.createElement("script")
    script.src = "https://apps.elfsight.com/p/platform.js"
    script.setAttribute('data-use-service-core', "true")
    script.defer = true
    document.body.appendChild(script)

    const targetNode = instagramContainer.current
    const config = { childList: true, subtree: true }

    const clickSocialPost = (e) => {
      const dl = {
        link: e.currentTarget.href,
        position:  e.currentTarget.dataset.position
      }

      setSocialPostClickedEvent(dl, consentAccepted)
    }

    const setPostsEventListener = (mutationList) => {

      mutationList.forEach((mutation)=>{
        if (mutation.type === "childList") {

         const posts = document.querySelectorAll('.eapps-instagram-feed-posts-item-link')

          if(posts?.length){
           posts.forEach((post, index)=>{
             post.setAttribute('data-position', `${index + 1}`)
             post.addEventListener('click',clickSocialPost);
           })
          }
        }
      })

    };

    const observer = new MutationObserver(setPostsEventListener);
    observer.observe(targetNode, config);

    return () => {
      observer.disconnect();
    }

  },[consentAccepted])

  return (
      <section
          id='instagram-section'
          style={{ backgroundColor: sectionBgColor }}
      >
        <div className="text-center py-5">
          <h2 style={{ color: sectionTextColor }}> { sectionTitle }</h2>
          { sectionDescription && <p style={{color: sectionTextColor}}> {sectionDescription} </p> }
          <div className={`${containerClassName} py-5`} data-elfsight-app-lazy="true" ref={instagramContainer}></div>
        </div>
      </section>
  );
}
