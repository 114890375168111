import React, { useEffect, useState } from 'react'
import { PrismicRichText } from "@prismicio/react"

// resources
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

// import Swiper core and required modules
import { Autoplay, Navigation } from "swiper"

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/navigation"

//assets
import useWindowSize from "../hooks/useWindowSize";

export const Testimonials = ({ slice }) => {
    const [testimonialItems, setTestimonialItems] = useState(slice.items || [])
    const { width } = useWindowSize()

    const navigation = {
        nextEl: ".swiper-next-testimonial",
        prevEl: ".swiper-prev-testimonial",
    }

    const swiperBreakPoints = {
        0: {
            slidesPerView: testimonialItems.length === 1? 1 : 3,
            loop:  testimonialItems.length === 1? false : true,
            centeredSlides: true
        },
        992: {
            slidesPerView: 2,
            loop: testimonialItems.length > 2? true : false,
            centeredSlides: false
        },
        1200: {
            slidesPerView: 3,
            loop: testimonialItems.length > 3? true : false,
        },
    }

    useEffect(() => {
        if(!width || slice.items.length === 0){
            return
        }

        let tempTestimonialItems = slice.items

        if(width >= 1200 && tempTestimonialItems.length <= 3){
            setTestimonialItems(tempTestimonialItems)
            return
        }else if(width >= 992 && tempTestimonialItems.length <= 2){
            setTestimonialItems(tempTestimonialItems)
            return
        }else if(width < 992 && tempTestimonialItems.length === 1){
            setTestimonialItems(tempTestimonialItems)
            return
        }

        // mobile size
        while(tempTestimonialItems.length < 6){
            tempTestimonialItems = tempTestimonialItems.concat(slice.items)
        }

        setTestimonialItems(tempTestimonialItems)
    }, [width, slice.items])

    if(testimonialItems.length === 0){
        return(<></>)
    }

    return(
        <section className='testimonials py-3 py-lg-5 mt-lg-2 mb-5 mb-lg-3 overflow-hidden'>
            <div className='testimonials__body py-lg-5 mb-lg-4 position-relative' data-num-items={testimonialItems.length}>
                <Swiper modules={[Autoplay, Navigation]}
                    navigation={navigation}
                    initialSlide={0}
                    speed={500}
                    breakpoints={swiperBreakPoints}
                    slidesPerView={3}
                    autoplay={{delay: 5000, disableOnInteraction: false}}
                >
                    {testimonialItems.map((item, index) => {
                        const { publication_logo, publication_name, quote, star_rating } = item
                        return(
                            <SwiperSlide className='d-flex justify-content-center align-items-center' key={index}>
                                <div className='testimonials__card-wrapper w-100'>
                                    <div className='testimonials__card position-relative'>

                                        {publication_logo.url && <div className="testimonials__card-logo">
                                            <img src={publication_logo.url} width="45px" alt={publication_logo.alt} />
                                        </div>}

                                        <div className='testimonials__card-name'>
                                            {publication_name && publication_name.text}
                                        </div>

                                        <div className='testimonials__card-quote py-4'>{quote.text}</div>

                                        <div className="testimonials__card-rating">
                                            {Array.from({ length: star_rating }, (_, idx) => (
                                                <span key={idx} role="img" aria-label="star">
                                                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.5468 1.02413C11.8409 0.0918112 13.1602 0.0918124 13.4542 1.02413L15.4926 7.48774C15.6239 7.90396 16.0099 8.18698 16.4463 8.18698H23.1047C24.0662 8.18698 24.4737 9.41136 23.704 9.98753L18.2664 14.0579C17.9263 14.3125 17.7842 14.7541 17.9119 15.1592L19.9767 21.7065C20.2689 22.6331 19.2015 23.3901 18.4237 22.8078L13.0998 18.8225C12.7445 18.5566 12.2565 18.5566 11.9013 18.8225L6.57733 22.8078C5.79953 23.3901 4.73216 22.6331 5.02437 21.7065L7.08915 15.1592C7.21691 14.7541 7.07478 14.3125 6.73471 14.0579L1.2971 9.98753C0.527388 9.41136 0.934886 8.18698 1.89637 8.18698H8.55475C8.99117 8.18698 9.37719 7.90396 9.50845 7.48774L11.5468 1.02413Z" fill="#00098C"/>
                                                    </svg>
                                                </span>
                                            ))}
                                        </div>

                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>

                <button className="swiper-prev-testimonial">
                    <span className="visually-hidden">Prev</span>
                    <FontAwesomeIcon icon={faChevronLeft} className="fa-solid" size="2xl" />
                </button>

                <button className="swiper-next-testimonial">
                    <span className="visually-hidden">Next</span>
                    <FontAwesomeIcon icon={faChevronRight} className="fa-solid" size="2xl" />
                </button>

            </div>
        </section>
    )
}