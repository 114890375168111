// core
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

// components
import Seo from "../components/Seo";
import Layout from "../components/Layout";

// page for rules, terms and conditions, cookies policy, privacy etc.
const DocumentPage = ({data}) => {
  const pageContent = data.prismicDocumentPage

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  // console.log(pageContent, activeDoc)

  return (
      <>
        <Layout activeDocMeta={activeDoc}>
          <Seo/>
          <div className='text-center' style={{paddingTop: "200px", height: '100vh'}}>TEST {pageContent.uid} PAGE</div>
        </Layout>
      </>
  );
}

export const query = graphql`
query DocumentPageQuery($uid: String, $id: String, $lang: String){
    prismicDocumentPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
    }
}
`
export default withPrismicPreview(DocumentPage)