// core
import React, { useEffect } from "react"
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { useLocation } from "@reach/router"
import axios from 'axios'

// components
import Seo from '../components/Seo'
import Layout from '../components/Layout'
import { SliceZone } from '@prismicio/react'
import { components } from "../slices/product-locator-page";
import { useConsent } from '../components/ConsentContext'

// constants
import { MIK_MAK_SCRIPT } from "../constants";
import { GOOGLE_MAP_API_URL } from "../constants/baseUrl"

// data layer
import {
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setLocatorFlavorSelectedEvent,
  setBuyNowClickedEvent,
  setGetDirectionsEvent
} from "../assets/js/data-layer"

// constant
import { PRODUCT_EARNS } from "../constants/productEans"

const ProductLocatorPage = ({data}) => {
  // console.log('data ' , data)
  const pageContent = data.prismicProductLocatorPage
  const siteData = data.site.siteMetadata
  const location = useLocation()

  const { meta_title, meta_description, social_card, body: slices, product_id: productId } = data.prismicProductLocatorPage.data


  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const consentAccepted = useConsent()


  // push product Ids (GTINs) to mikmak dataLayer and added TAG
  useEffect(()=>{
    const script = document.createElement("script")
    script.type = 'text/javascript'
    script.innerText = `
     window.swnDataLayer = { product_eans: "${productId}" }; 
      ${ MIK_MAK_SCRIPT }
    `

    // data layer - MikMak events
    const checkMikMakDataLayer = setInterval(() => {
      if(window.swnDataLayer){
        let selectedFlavor = ''
        let newProductClicked = false

        window.swnDataLayer.widget_callback = async (data) => {
          try{
            if(data.action === 'buy'){
              // 'locator_retailer_selected' event
              const supplier = data.store.name.toLowerCase()
              const flavor = data.product.name.toLowerCase()
              const getCategory = function(){
                for(const item in PRODUCT_EARNS){
                  if(item === data.product.ean){
                    return PRODUCT_EARNS[item].cat?PRODUCT_EARNS[item].cat:''
                  }
                }
              }
              const category = getCategory()
              const size = data.product.pkg?data.product.pkg.toLowerCase():''
              const price = `${data.product.price}`
              setRetailerSelectedEvent({
                'supplier': supplier,
                'flavor': flavor,
                'category': category,
                'size': size,
                'price': price
              }, consentAccepted)
            }else if(data.action === 'clkRoute'){
              // 'get_directions' event
              const supplier = data.store.name.toLowerCase()

              setGetDirectionsEvent({
                'url': location.href,
                'supplier': supplier
              }, consentAccepted)
            }else if(data.action === 'geoloc'){
              // 'locator_address_searched' event
              const zipCode = data.location.zipCode
              if(zipCode){
                const locationData = await getAddressFromZipCode(zipCode)
                if(locationData){
                  setLocatorAddressSearchedEvent(locationData, consentAccepted)
                }
              }
            }else if(data.action === 'clk'){
              newProductClicked = true
            }else if(data.action === "apires" && newProductClicked){
              // 'locator_flavor_selected' event
              if(selectedFlavor !== data.product.name){
                const flavor = data.product.name.toLowerCase()
                const getCategory = function(){
                  for(const item in PRODUCT_EARNS){
                    if(item === data.product.ean){
                      return PRODUCT_EARNS[item].cat?PRODUCT_EARNS[item].cat:''
                    }
                  }
                }
                const category = getCategory()
                const size = data.product.pkg?data.product.pkg.toLowerCase():''
                const method = ''
  
                setLocatorFlavorSelectedEvent({
                  'flavor': flavor,
                  'category': category,
                  'size': size,
                  'method': method
                }, consentAccepted)
              }
              newProductClicked = false
            }
          }catch(e) {
            console.error(e)
          }
        }

        clearInterval(checkMikMakDataLayer)
      }
    }, 300)

    document.body.appendChild(script)

    return () => {
      // clean up the script when the component in unmounted
      document.body.removeChild(script)

      clearInterval(checkMikMakDataLayer)
    }
  },[consentAccepted, productId])


  // data layer - locator pageview event
  useEffect(() => {
    const dlData = {
      title: meta_title.text || siteData.title,
      url: location.href,
      path: location.pathname,
      referrer: (sessionStorage.getItem('previous_page_url'))?
        sessionStorage.getItem('previous_page_url') : location.pathname,
      language: lang
    }

    setLocatorPageViewEvent(dlData, consentAccepted)
  }, [pageContent, consentAccepted, lang, location, meta_title, siteData])

  // data layer - buy now clicked event - header
  useEffect(() => {
    if(sessionStorage.getItem('buy_now_clicked') === null) return
    const dlData = {
      flavor: '',
      category: ''
    }
    setBuyNowClickedEvent(dlData, consentAccepted)

    sessionStorage.removeItem('buy_now_clicked')
  }, [consentAccepted])


  // get full address from zipcode using google map API
  const getAddressFromZipCode = async (zipcode) => {
    try{
      const response = await axios({
        url: GOOGLE_MAP_API_URL('AIzaSyB14cC5KRuDXStxieEY6zbN_NdxKLLMaeg', zipcode),
        method: 'GET',
        headers: {
          "Content-Type": "application/json; charset=utf-8"
        }
      })

      if(response.status === 200){
        const result = response.data.results[0]
        if(!result){
          throw new Error('No address found.')
        }

        return result.address_components.reduce((cus, component) => {
          const types = component.types

          if(types.includes('postal_code')){
            cus.zc = component.long_name
          }else if(types.includes('locality')){
            cus.cty = component.long_name
          }else if(types.includes('country')){
            cus.ctry = component.short_name
          }else if(types.includes('administrative_area_level_1')){
            cus.st = component.long_name
          }
          return cus
        }, { addr: result.formatted_address })
      }else{
        throw new Error(`Error: ${response.status}, ${response.statusText}`)
      }
    }catch(err){
      console.error(err.message)
      return null
    }
  }

  return (
      <Layout currentPage='prod-locator' activeDocMeta={activeDoc} setPreviousPage={false}>
        <Seo
            title={meta_title?.text}
            description={ meta_description?.text}
            image={social_card?.url}
            activeDocMeta={activeDoc}
        />
        <SliceZone components={components} slices={slices}/>
      </Layout>
  );
}

export const query = graphql`
query ProductLocatorPageQuery($uid: String, $id: String, $lang: String){
    prismicProductLocatorPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
            product_id
            social_card {
                url 
            }
            meta_description {
                text
            }
            meta_title {
                text
            }
            body {
             ... on PrismicProductLocatorPageDataBodyLocatorHero {
                id
                slice_type
                primary {
                   title {
                      text
                      richText
                   }
                   banner {
                      alt
                      url
                   }
                }
             }
             ... on PrismicProductLocatorPageDataBodyLocatorSection {
                id
                slice_type
             }
            }
        }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
}
`
export default withPrismicPreview(ProductLocatorPage)