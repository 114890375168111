// core
import React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { SliceZone } from '@prismicio/react'

// components
import Seo from "../components/Seo";
import Layout from "../components/Layout";
import { components } from "../slices/generic-page";

// Generic page template to use with slices
const GenericPage = ({data}) => {
  const pageContent = data.prismicGenericPage
  const pageData = data.prismicGenericPage.data

  const { body: slices } = data.prismicGenericPage.data

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  // console.log(pageContent, activeDoc)

  return (
      <>
        <Layout currentPage={pageContent.uid} activeDocMeta={activeDoc}>
          <Seo
              title={pageData.meta_title?.text }
              description={pageData.meta_description?.text}
              image={pageData.social_card?.url}
              activeDocMeta={activeDoc}
          />
          <SliceZone components={components} slices={slices} context={{data: pageContent, activeDoc}}/>
        </Layout>
      </>
  );
}

export const query = graphql`
query GenericPageQuery($uid: String, $id: String, $lang: String){
    prismicGenericPage(uid: { eq: $uid }, id: { eq: $id }, lang: { eq: $lang }) {
        _previewable
        url
        uid
        type
        id
        lang
        alternate_languages {
          id
          type
          lang
          uid
        }
        data {
          meta_title {
            text
          }
          meta_description {
            text
          }
          social_card {
            url
          }
          body {
            ... on PrismicGenericPageDataBodyFullWidthText {
              id
              slice_type
              items {
                vertical_spacing
                spacing_top
                spacing_bottom
                text {
                  richText
                }
                text_size
                text_max_width_desktop
                text_max_width_tablet
                text_max_width_mobile
                text_color
                background_color
              }
            }
            ... on PrismicGenericPageDataBodyCrosslink {
              id
              slice_type
              primary {
                cta_button_text {
                  text
                  richText
                }
                cta_button_link {
                  url
                }
               }
            }
            ... on PrismicGenericPageDataBodyFullWidthImageText {
              id
              slice_type
              primary {
                cta_button { 
                  url
                }
                cta_button_text { 
                  richText
                  text
                }
                background_image {
                  url
                  alt
                }
                image_fixed_height
                image_height_desktop
                image_height_tablet
                image_height_mobile
              }
              items {
                text_size
                text_color
                text_space_bottom
                text {
                  richText
                }
              }
            }
            ... on PrismicGenericPageDataBodyHalfTextHalfImage {
              id
              slice_type
              items {
                text_bg_image {
                  url
                  alt
                }
                opposite_large_image {
                  url
                  alt
                }
                text_bg_image_align
                text_on_right
                title_text {
                  richText
                }
                body_text {
                  richText
                }
                action_button_text
              }
            }
            ... on PrismicGenericPageDataBodyProductCarousel {
              id
              slice_type
              primary {
                background_image {
                  url
                  alt
                }
                category {
                  document {
                    ... on PrismicProductCategory {
                      id
                      data {
                        category_new_text
                        category_name
                        taxonomy_product_category
                        category_item {
                          category_item_link {
                            document {
                              ... on PrismicProductDetailsPage {
                                id
                                url
                                data {
                                  product_name {
                                    text
                                    richText
                                  }
                                  product_image {
                                    url
                                    alt
                                  }
                                  product_type
                                  product_new_text
                                  taxonomy_product_size
                                  taxonomy_product_flavor
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            ... on PrismicGenericPageDataBodyPreformattedText {
                id
              slice_type
              primary {
                 content {
                     text             
                 }
              }
            }
            ... on PrismicGenericPageDataBodyTestimonials {
              id
              slice_label
              slice_type
              items {
                publication_logo {
                  url
                  gatsbyImageData(sizes: "75px")
                  alt
                }
                publication_name {
                  richText
                  text
                }
                quote {
                  text
                  richText
                }
                star_rating
              }
            }
          }
        }
    }
}
`
export default withPrismicPreview(GenericPage)