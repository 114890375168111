// core
import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { useLocation } from "@reach/router";

// components
import useWindowSize from "../hooks/useWindowSize";
import {Link} from "gatsby";

// data-layer
import { setLearnMoreClickedEvent, setBuyNowClickedEvent } from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

export const FullWidthImageText = ({ slice, context }) => {
  // const { data } = context
  const location = useLocation()
  const consentAccepted = useConsent()

  const { width } = useWindowSize()
  const bgImgURL = slice.primary.background_image.url || ""
  const bgImgAlt = slice.primary.background_image.alt || ""
  const imageFixedHeight = slice.primary.image_fixed_height || false
  const imageHeightMobile = slice.primary.image_height_mobile || 400
  const imageHeightTablet = slice.primary.image_height_tablet || 400
  const imageHeightDesktop = slice.primary.image_height_desktop || 400
  const ctaButtonLink = slice.primary.cta_button?.url || ''
  const ctaButtonText = slice.primary.cta_button_text?.text || ''

  const TextComponent = ({ textSize, field, style }) => {
    const Component = textSize === "small" ? "p" : "h2";
    return <Component style={style}><PrismicRichText field={field} /></Component>;
  };

  const getImageHeight = (width) => {
    if(!imageFixedHeight) {
      return
    }

    if (width < 576) {
      return imageHeightMobile + 'px';
    } else if (width >= 576 && width <= 768) {
      return imageHeightTablet + 'px';
    } else {
      return imageHeightDesktop + 'px';
    }
  };

  const clickableBanner = ctaButtonLink && !ctaButtonText

  return (
    <section className="full-width-image-text">
      <div
        className={`full-width-image-text__bg${imageFixedHeight ? ' full-width-image-text__bg--fixed-height' : ''}`}
        {...(imageFixedHeight ? { style: { height: getImageHeight(width) } } : {})}
        >
        { clickableBanner && <a href={ctaButtonLink} title='Our story' target='_self'>
          <img src={bgImgURL} alt={bgImgAlt} width='100' loading='lazy'/>
          </a> }
        { !clickableBanner && <img src={bgImgURL} alt={bgImgAlt} width='100' loading='lazy'/> }
      </div>
      <div className="full-width-image-text__body">
        {
          slice.items.map((sliceItem, sliceIndex) => {
            const textSize = sliceItem.text_size.toLowerCase() || "small"
            const textColor = sliceItem.text_color || "#FFFFFF"
            const textSpaceBottom = sliceItem.text_space_bottom || "0px"
            const textContent = sliceItem.text.richText || ""
            // console.log('sliceIndex', sliceIndex)
            return (<div key={`${slice.id}${sliceIndex}`}>
                   <TextComponent
                      key={sliceIndex}
                      textSize={textSize}
                      field={textContent}
                      style={{
                        color: textColor,
                        marginBottom: textSpaceBottom + 'px'
                      }}
                  />
                </div>
            );
          })
        }
        { ctaButtonText && <div className="py-3">
          <Link
              to={ctaButtonLink}
              target="_blank"
              title={ctaButtonText}
              className="button-transparent crosslink__cta-button py-2"
              onClick={()=> {
                  const dl = {
                    url: ctaButtonLink,
                    referrer: location.pathname,
                    name: ctaButtonText.toLowerCase()
                  }
      
                  setLearnMoreClickedEvent(dl, consentAccepted)

                  // buy now clicked event - link to locator
                  if(ctaButtonLink === "/locator"){
                    setBuyNowClickedEvent({
                      flavor: null,
                      category: null
                    }, consentAccepted)
                  }
                }
              }
          >
            {ctaButtonText}
          </Link>
        </div> }
      </div>
    </section>
  );
};
