const SOCIAL_PLATFORM = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  TWITTER: 'twitter'
}

const SOCIAL_PLATFORM_URL = {
  INSTAGRAM: 'https://www.instagram.com/whiteclawzero',
  FACEBOOK: 'https://www.facebook.com/whiteclawzero',
  TWITTER: 'https://twitter.com/whiteclawzero'
}

export { SOCIAL_PLATFORM, SOCIAL_PLATFORM_URL }