// core
import React from "react";
import { PrismicRichText } from "@prismicio/react";

// components
import useWindowSize from "../hooks/useWindowSize";

export const FullWidthText = ({ slice }) => {
  // console.log("fullWidthText: ", slice);

  const { width } = useWindowSize()

  const TextComponent = ({ textType, field, style }) => {
    const Component = textType === "paragraph" ? "p" : "h4";
    return <Component style={style}><PrismicRichText field={field} /></Component>;
  };

  const fluidCalc = (pixelSize, windowWidth, noMinimum = false) => {
    if(pixelSize === 0) {
      return '0px';
    }

    const designWidth = windowWidth <= 768 ? 390 : 1440;
    const vwSize = (pixelSize / designWidth) * 100;
    const vwSizeRounded = Math.round(vwSize * 100) / 100;
    const outputCalc = noMinimum ? `max(${pixelSize}px, ${vwSizeRounded}vw)` : `${vwSizeRounded}vw`;

    return outputCalc;
  }

  return (
    slice.items.map((sliceItem, sliceIndex) => {
      const bgColor = sliceItem.background_color || "#00098c"
      const verticalSpacing = sliceItem.vertical_spacing || 20
      const spacingTop = sliceItem?.spacing_top
      const spacingBottom = sliceItem?.spacing_bottom
      const verticalPixelSize = parseInt(verticalSpacing, 10)
      const text = sliceItem.text.richText || ""
      const textType = sliceItem.text.richText[0].type || "paragraph"
      const textColor = sliceItem.text_color || "#FFFFFF"
      const textSize = sliceItem.text_size.toLowerCase() || "medium"
      const textMaxWidthMobile = sliceItem.text_max_width_mobile || "100%"
      const textMaxWidthTablet = sliceItem.text_max_width_tablet || "100%"
      const textMaxWidthDesktop = sliceItem.text_max_width_desktop || "100%"

      const getTextMaxWidth = (width) => {
        if (width < 576) {
          return textMaxWidthMobile + '%';
        } else if (width >= 576 && width < 768) {
          return textMaxWidthTablet + '%';
        } else {
          return textMaxWidthDesktop + '%';
        }
      };

      return (
        <section
          className={`full-width-text full-width-text--${textSize}`}
          key={sliceIndex}
          style={{
            backgroundColor: bgColor,
            paddingTop: fluidCalc(spacingTop !== null && spacingTop !== undefined ? spacingTop : verticalPixelSize, width),
            paddingBottom: fluidCalc(spacingBottom !== null && spacingBottom !== undefined ? spacingBottom : verticalPixelSize, width)
          }}
        >
          <TextComponent
            textType={textType}
            field={text}
            style={{
              color: textColor,
              maxWidth: getTextMaxWidth(width)
            }}
          />
        </section>
      );
    })
  );
};
