// core
import React from 'react'
import { SliceZone } from '@prismicio/react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"

//  slices
import { components } from '../slices/campaign-page'

// components
import Layout from '../components/Layout'
import Seo from '../components/Seo'


const CampaignPage = ({data}) => {
// console.log(data)
  const pageContent = data.prismicCampaignPage

  const { meta_title, meta_description, social_card, body: slices } = data.prismicCampaignPage.data


  const { lang, type, url, uid } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  return (
      <Layout currentPage={`campaign-page_${uid}`} activeDocMeta={activeDoc}>
        <Seo
            title={ meta_title?.text }
            description={ meta_description?.text }
            image={ social_card?.url }
            activeDocMeta={activeDoc}
        />
        <SliceZone slices={ slices } components={ components } />
      </Layout>
  )
}

export const query = graphql`
query CampaignPageQuery($uid: String, $id: String, $lang: String) {
  prismicCampaignPage(
    uid: { eq: $uid }
    id: { eq: $id }
    lang: { eq: $lang }
  ) {
    _previewable
    url
    uid
    type
    id
    lang
    alternate_languages {
      id
      type
      lang
      uid
    }
    data {
      body {
        ... on PrismicCampaignPageDataBodyHeroSection {
          id
          slice_type
          primary {
            additional_class_name
            background_color
            cta_button_text {
              richText
              text
            }
            cta_button_link {
              url
            }
            background_image {
              alt
              url
            }
            background_image_mobile {
              alt
              url
            }
          }
        }
        ... on PrismicCampaignPageDataBodyHeroVideoSection {
          id
          slice_type
          primary {
            page_language
            background_color
            button_text {
              text
              richText
            }
            button_link {
              url
            }
          }
        }
        ... on PrismicCampaignPageDataBodyYoutubeVideoSection {
          id
          slice_type
          primary {
            full_view_width
            video_poster_desktop {
              alt
              url
            }
            video_poster_mobile {
              alt
              url
            }
            section_title {
              richText
              text
            }
            play_button_text {
              richText
              text
            }
            play_button_text_color
            play_button_image {
              alt
              url
            }
            youtube_src {
              text
              richText
            }
          }
        }
        ... on PrismicCampaignPageDataBodyCampaignCtaSection {
          id
          slice_type
          primary {
            button_text {
              text
            }
            button {
              url
            }
            title {
              text
              richText
            }
            description {
              text
              richText
            }
          }
        }
        ... on PrismicCampaignPageDataBodyCampaignRangeSection {
          id
          slice_type
          items {
            product_image {
              alt
              url
            }
          }
          primary {
            background_image {
              alt
              url
            }
            background_image_mobile {
              alt
              url
            }
            title {
              text
              richText
            }
          }
        }
        ... on PrismicCampaignPageDataBodyCrosslink {
          id
          slice_type
          primary {
            cta_button_text {
              text
              richText
            }
            cta_button_link {
              url
            }
          }
        }
        ... on PrismicCampaignPageDataBodyInstagramSection {
          id
          slice_type
          primary {
            title {
              richText
              text
            }
            description {
              richText
              text
            }
            copy_color
            background_color
            widget_view
          }
        }
        ... on PrismicCampaignPageDataBodyPromoModule {
           id
           slice_type
           slice_label
           items {
             image {
               url
               alt
             }
             button_link {
               url
             }
             button_text {
                text
             }
             subtitle {
               text
             }
           }
           primary {
             title {
               text
             }
           }
        }
      }
    }
  }
 }
`

export default withPrismicPreview(CampaignPage)


