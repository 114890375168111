import { AllFlavorsVP } from './AllFlavorsVarietyPack'
import { AllFlavors } from './AllFlavors'
import { InstagramSection } from "../InstagramSection";
import { NutritionAndIngredientsTable } from "./NutritionAndIngredientsTable";
import { PromoModule } from "../PromoModule";
import { InstagramGallery }  from "../InstagramGallery";

export const pdpComponents = {
    all_flavors: AllFlavors,
    all_flavors_vp: AllFlavorsVP,
    instagram_section: InstagramSection,
    nutrition_and_ingredients_table: NutritionAndIngredientsTable,
    promo_module: PromoModule,
    instagram_gallery: InstagramGallery
}