import React from 'react'
import { Link } from 'gatsby'

//assets
import useWindowSize from "../hooks/useWindowSize";


export const HeroSection = ({ slice }) => {
  const { width } = useWindowSize()

  const additionalClass = slice.primary.additional_class_name

  const button = {
    href: slice.primary.cta_button_link?.url || '',
    text: slice.primary.cta_button_text?.text || ''
  }

  const bg = {
    imageMobile: slice.primary.background_image_mobile?.url || '',
    imageScreen: slice.primary.background_image?.url || '',
    color: slice.primary.background_color || '#ffffff',
    alt: slice.primary.background_image?.alt || '',
  }

  const currentBG = width <= 576 ? bg.imageMobile : bg.imageScreen

  return (
      <section className={`hero ${additionalClass ? `hero-section__${additionalClass}` : ''} position-relative`}
               style={{backgroundColor: `${bg.color || null}`}}>
        <a href={button.href} className='hero-section__link'>
          <div className='hero__bg w-100 position-relative'>
            { width && <img src={currentBG} alt={bg.alt} className='hero__bg-img' loading='eager'/> }
          </div>
        </a>
        {button.text && <Link
            to={button.href}
            title={button.text}
            className="button-white hero__button"
        >
          {button.text}
        </Link> }
        {width <= 576 && button.text &&  <Link
            to={button.href}
            title={button.text}
            className="button-black hero__button"
        >
          {button.text}
        </Link>}
      </section>
  )
}