import React from 'react'
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";



export const CampaignCtaSection = ({ slice }) => {
  // console.log(slice)
  const titleRows = slice.primary.title.richText
  const descriptionRows = slice.primary.description.richText


  const button = {
    href: slice.primary.button.url || '',
    text: slice.primary.button_text.text || '',
  }

  return (
      <section className='campaign-cta-section'>
          <div className='row campaign-cta-section__content m-0'>
            <h2 className='campaign-cta-section__title'>
              {titleRows && titleRows.map((row) => {
                return  <span key={row.text}><PrismicRichText field={[row]}/><br/></span>
              })}
            </h2>

            <p className='campaign-cta-section__description'>
              {descriptionRows.length && descriptionRows.map((row, index)=>{
                return <span key={`paragraph-${index}`}><PrismicRichText field={[row]}/><br/></span>
              })}
            </p>

            <Link
                to={button.href}
                title={button.text}
                className="button-white campaign-cta-section__button"
            >
              {button.text}
            </Link>
          </div>
      </section>
  )
}