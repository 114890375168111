import { HeroSection } from "../HeroSection";
import { HeroVideoSection } from "../HeroVideoSection";
import { YouTubeVideoSection } from "../YoutubeVideoSection";
import { InstagramSection } from "../InstagramSection";
import { CrossLink } from "../CrossLink";
import { ProductRange } from "../ProductRange";
import { ProductCarousel } from "../ProductCarousel";
import { SubscribeForm } from "../SubscribeForm";
import { FullWidthText } from "../FullWidthText";
import { FullWidthImageText } from "../FullWidthImageText";
import { FindNearYou } from "../FindNearYou";
import { ComingSoon } from "./ComingSoon";
import { Testimonials } from "../Testimonials";
import { InstagramGallery } from "../InstagramGallery";

export const components = {
  hero_section: HeroSection,
  hero_video_section: HeroVideoSection,
  youtube_video_section: YouTubeVideoSection,
  crosslink: CrossLink,
  instagram_section: InstagramSection,
  product_range: ProductRange,
  product_carousel: ProductCarousel,
  subscribe_form: SubscribeForm,
  full_width_text: FullWidthText,
  full_width_image_text: FullWidthImageText,
  find_near_you: FindNearYou,
  coming_soon: ComingSoon,
  testimonials: Testimonials,
  instagram_gallery: InstagramGallery
}