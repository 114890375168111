import React from 'react';

const BVInlineRating = ({id, url}) => {

  return  <div data-bv-show="inline_rating"
               data-bv-product-id={id}
               data-bv-redirect-url={url}
               className='plp-rating-summary'
  >
  </div>;
}

export default BVInlineRating