import React, { useState } from 'react'

import ImgWithFallback from "../components/ImgWithFallback";

// assets
import * as images from "../assets/images/icons";

export const YouTubeVideoSection = ({ slice }) => {
  const [isPlay, setIsPlay] = useState(false)

  const videoContainerWidth = slice.primary.full_view_width ? '100%' : '85%'

  const title = slice.primary.section_title?.richText || []
  const backgroundImageThumb = slice.primary.video_poster_desktop?.url || ''
  const youtubeSrc = slice.primary.youtube_src.text || ''

  const playButton = {
    text: slice.primary.play_button_text?.text || '',
    textColor: slice.primary.play_button_text_color || "#ffffff",
    image: slice.primary.play_button_image?.url || images.playVideoBtnPng
  }

  return (
      <section id="film" className="film" >
        {slice.primary.section_title.text && <h3 className='film__title py-5'>
          { title.map((row) => {
            return  <span key={row.text}>{row.text}<br/></span>})
          }
        </h3>}

        <div className="film__content-wrap"
             style={{ backgroundImage: isPlay && youtubeSrc ? "unset" : `url(${backgroundImageThumb})`, width: `${videoContainerWidth}`}}
        >
          { !isPlay && <div className="film__content">
            <div>

              {/* eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions*/}
              <a className="film__video-btn" onKeyDown={() => { setIsPlay(true) }} onClick={() => { setIsPlay(true) }}>
                <ImgWithFallback
                    classNameImg='w-100'
                    src={playButton.image}
                    alt='Play button'
                    fallbackSrc={playButton.image}
                />
              </a>
              <p className="film__button-text"
                 style={{color: playButton.textColor}}>
                { playButton.text }
              </p>
            </div>
          </div>  }

          { isPlay && youtubeSrc && <div className="film__inline-video">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                  id="video"
                  title={youtubeSrc}
                  className="embed-responsive-item"
                  src={`${youtubeSrc}?autoplay=1&amp;modestbranding=1&amp;showinfo=0&amp;rel=0`}
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen
              >
              </iframe>
            </div>
          </div> }
        </div>
      </section>
  )
}