import React from 'react'

export const ComingSoon = ({ slice }) => {

  const image = {
    src: slice.primary.image.url || '',
    alt: slice.primary.image.alt || ''
  }
  const description = slice.primary.description.text


  return (
      <section className="coming-soon">
        <img src={image.src} className="coming-soon-image" alt={image.alt}/>
        <p className='coming-soon-key-message'>{description}</p>
      </section>
  )
}