import * as Yup from "yup";
import { checkLegalAge   } from "../assets/js";
import {ONLY_ALPHABET_CHARACTERS} from "../constants";

const checkIsDateValid = (dateString) => {
  var parts = dateString.split("/");
  var day = parseInt(parts[1], 10);
  var month = parseInt(parts[0], 10);
  var year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) {
      return false;
  }

  // Check the number of days in the month
  var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (month === 2 && year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0)) {
      // Leap year
      if (day > 29) {
          return false;
      }
  } else {
      if (day > monthLength[month - 1]) {
          return false;
      }
  }

  return true;
}

export const newsLettersFormValidationSchema = (parmsValue) => {
  return Yup.object({
      firstname: Yup.string().required("This field is required."),
      lastname: Yup.string().required("This field is required."),
      email: Yup.string().email("Invalid email address").required("This field is required."),
      birthdate: Yup.date().required("Please enter your birthday.")
          .test("birthdate", "Please provide a valid birthday MM/DD/YYYY", (value) => { return !(value?.length < 10) })
          .test("birthdate", "Please input a valid date of birth.", () => {
            return checkIsDateValid(parmsValue);
        })
          .test("birthdate", "To subscribe, you must be of legal drinking age", (value) => {
              return checkLegalAge(value);
          }),
      state: Yup.string().required('This field is required.'),
      agreeCheckbox: Yup.bool()
          .oneOf([true], 'This field is required.')
  });
};

export const newsLettersFormValidationSchemaWithoutState = (parmsValue) => {
  return  Yup.object({ // validate form
    firstname: Yup.string().required("This field is required."),
    lastname: Yup.string().required("This field is required."),
    email: Yup.string().email("Invalid email address").required("This field is required."),
    birthdate: Yup.date().required("Please enter your birthday.")
        .test("birthdate", "Please provide a valid birthday MM/DD/YYYY", (value) => { return !(value?.length < 10) })
        .test("birthdate", "Please input a valid date of birth.", () => {
          return checkIsDateValid(parmsValue);
      })
        .test("birthdate", "To subscribe, you must be of legal drinking age", (value) => {
          return checkLegalAge(value);
        }),
    agreeCheckbox: Yup.bool()
        .oneOf([true], 'This field is required.')
  })
};


export const ageGateDOBFormValidationSchema  = (validations) => {
  return Yup.object().shape({
    month: Yup.number()
        .min(1, validations.invalidMonth)
        .max(12, validations.invalidMonth)
        .required(validations.monthRequired),
    day: Yup.number()
        .test("valid-day", validations.invalidDay, function (value) {
          const month = this.parent.month;
          if (!value || !month) {
            return true;
          }
          const year = this.parent.year || new Date().getFullYear();
          const daysInMonth = new Date(year, month, 0).getDate();
          return value >= 1 && value <= daysInMonth;
        })
        .required(validations.dayRequired),
    year: Yup.string()
      .matches(/^[0-9]+$/,"Must be only digits")
      .min(4,'Must be exactly 4 digits')
      .max(4,'Must be exactly 4 digits')
      .required(validations.yearRequired),
  });
}


export const DSARFormSchema = (validations) => Yup.object().shape({
  email: Yup.string().required(validations.provideEmail).email(validations.provideValidEmail),
  inquiryTypeID: Yup.string().required(validations.selectTopic),
  firstName: Yup.string()
      .required(validations.provideFirstName)
      .min(2, validations.tooShort)
      .max(32, validations.tooLong)
      .matches(ONLY_ALPHABET_CHARACTERS, validations.onlyAlpha),
  lastName: Yup.string()
      .required(validations.provideLastName)
      .min(2, validations.tooShort)
      .max(32, validations.tooLong)
      .matches(ONLY_ALPHABET_CHARACTERS, validations.onlyAlpha),
  birthDate: Yup.date().required(validations.enterBday).typeError(validations.validBday),
  message: Yup.string().required(validations.provideMsg),
});