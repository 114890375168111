const BASE_URL = {
  PROD: "https://api.markanthony.com/ConsumerResponses/response",
  DEV: "",
  LOCAL: "",
  TEST: ''
}

const SUBSCRIPTION_SIGN_UP_BASE_URL = {
  PROD: "https://api.markanthony.com/ActiveCampaign/Contacts",
  TEST: ''
}

const GOOGLE_MAP_API_URL = (api_key, zipcode = null) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?${zipcode? 'address=' + zipcode + '&' : ''}key=${api_key}`
}

export  { BASE_URL, SUBSCRIPTION_SIGN_UP_BASE_URL, GOOGLE_MAP_API_URL }