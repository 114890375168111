// core
import React, { useState } from 'react'

// form validation
import { Field } from "formik"

// components
import FieldErrorMessage from './FieldErrorMessage'
import WindowModal from './WindowModal'

// resources
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const HiddenFields = ({ inquiryTypeID,
                        lang,
                        validations,
                        submitCount,
                        errors,
                        toggleExtraFieldsContainer,
                        extraFieldsBtnText,
                        handleBatchNumberChange,
                        batchNumberValue,
                        values,
                        extraFieldsContainer,
                        batchCharValidationMsg,
                        handleTimeStampChange,
                        timeStampValue,
                        handleLineNumberChange,
                        lineNumberValue,
                        lineNumberValidationMsg
                      }) => {

  const [showWindowModal, setShowWindowModal] = useState(false)
  const [windowModalType, setWindowModalType] = useState("")

  // Window Modal Func
  const closeWindowModal = () => setShowWindowModal(false)
  const openWindowModal = (e, windowModalType) => {
    e.preventDefault()
    setWindowModalType(windowModalType)
    setShowWindowModal(true)
  }

  return (
      <>
        {/* InquiryType 100000003 - Quality Concern*/}
        <div className={"row hidden-block batch-code " + (inquiryTypeID === "100000003" ? "" : "toggle-hide")}>
          <div className='col-12 pt-3'>
            <p className="pb-4">
              {validations.qualityTxt01}
              &nbsp;
              {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
              <a href="#" onClick={(e) => openWindowModal(e, 'batchimage')}>
                {validations.viewImage}
              </a>.
            </p>
            <p className="pb-4">
              {validations.qualityTxt02}
            </p>
            <p className="form-btn-container pb-5">
              <button type="button" className="form-btn form-btn-long-text" onClick={(e) => openWindowModal(e, 'decipherbatchnumber')}>{validations.decipherBatch}</button>
            </p>
            {/* WindowModal Component Batch / Decipher */}
            <WindowModal
                showWindowModal={showWindowModal}
                closeWindowModal={closeWindowModal}
                windowModalType={windowModalType}
                lang={lang}
            />
          </div>

          <div className="col-12 form-group">
            {/* Extra Fields Checkbox */}
            <label className="btn btn-block extra-fields-checkbox">
              <Field
                  type="checkbox"
                  name="extraFieldsCheckbox"
                  id="extra-fields-checkbox"
                  className="visually-hidden"
                  onClick={toggleExtraFieldsContainer}
              />
              {extraFieldsBtnText}
            </label>

            {submitCount > 0 && errors.extraFieldsCheckbox !== "" ? (
                <FieldErrorMessage error={errors.extraFieldsCheckbox} />
            ) : null}
            {/* Eof extra fields checkbox */}
          </div>

          <div className="col-12 col-md-4 form-group">
            {/* Batch number */}
            <Field
                type="text"
                id="form-batchcode"
                className="form-control"
                name="batchNumber"
                maxLength={9}
                placeholder={validations.batchNumberDD}
                onChange={handleBatchNumberChange}
                value={(values.batchNumber = batchNumberValue)}
                disabled={extraFieldsContainer}
            />
            {submitCount > 0 && errors.batchNumber !== "" && values.batchNumber !== "N/A" ? (
                <FieldErrorMessage error={errors.batchNumber} />
            ) : null}

            {batchCharValidationMsg !== "" ? (
                <FieldErrorMessage error={batchCharValidationMsg} />
            ) : null}
            {/* Eof batch number */}
          </div>

          <div className="col-12 col sm-6 col-md-4 form-group">
            {/* Time Stamp */}
            <Field
                type="text"
                id="form-timeStamp"
                className="form-control"
                name="timeStamp"
                maxLength={5}
                placeholder={validations.timeStampDD}
                onChange={ handleTimeStampChange }
                value={(values.timeStamp = timeStampValue)}
                disabled={extraFieldsContainer}
            />

            {submitCount > 0 && errors.timeStamp !== "" && values.timeStamp !== "N/A" ? (
                <FieldErrorMessage error={errors.timeStamp} />
            ) : null}
            {/* Eof time stamp */}
          </div>

          <div className="col-12 col-sm-6 col-md-4 form-group">
            {/* Line number */}
            <Field
                type="text"
                id="form-lineNumber"
                className="form-control"
                name="lineNumber"
                maxLength={2}
                placeholder={validations.lineNumberDD}
                onChange={ handleLineNumberChange }
                value={(values.lineNumber = lineNumberValue)}
                disabled={extraFieldsContainer}
            />

            {submitCount > 0 && errors.lineNumber !== "" && values.lineNumber !== "N/A" ? (
                <FieldErrorMessage error={errors.lineNumber} />
            ) : null}

            {lineNumberValidationMsg !== "" ? (
                <FieldErrorMessage error={lineNumberValidationMsg} />
            ) : null}
            {/* Eof line number */}
          </div>

          {/* Extra Fields - If 'I don't have batch number is clicked' */}
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Package type */}
            <label className="select">
              <Field
                  as="select"
                  name="packageType"
                  className="extra-fields-js form-control"
              >
                <option value="">{validations.selectPackageTypeDD}</option>
                <option value={validations.canDD} data-subject={validations.canDD}>
                  {validations.canDD}
                </option>

                <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />
              </Field>

              {submitCount > 0 && errors.packageType !== "" ? (
                  <FieldErrorMessage error={errors.packageType} />
              ) : null}
            </label>
            {/* Eof package type */}
          </div>
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Package Quantity */}
            <label className="select" htmlFor='packageQuantity'>
              <Field
                  as="select"
                  className="extra-fields-js form-control"
                  name="packageQuantity"
              >
                <option value="">{validations.packageQuantityDD}</option>
                <option value={validations.singleDD} data-subject={validations.singleDD}>
                  {validations.singleDD}
                </option>
                <option value={validations.sixPackDD} data-subject={validations.sixPackDD}>
                  {validations.sixPackDD}
                </option>
                <option value={validations.eightPackDD} data-subject={validations.eightPackDD}>
                  {validations.eightPackDD}
                </option>
                <option value={validations.twelvePackDD} data-subject={validations.twelvePackDD}>
                  {validations.twelvePackDD}
                </option>
                <option value={validations.twentyFourPackDD} data-subject={validations.twentyFourPackDD}>
                  {validations.twentyFourPackDD}
                </option>
              </Field>
              <FontAwesomeIcon icon={faChevronDown} className="fa-solid" />
            </label>
            {submitCount > 0 && errors.packageQuantity !== "" ? (
                <FieldErrorMessage error={errors.packageQuantity} />
            ) : null}
            {/* Eof package quantity */}
          </div>
          <div className={'col-12 col-md-4 form-group ' + (extraFieldsContainer? "" : "d-none")}>
            {/* Flavor name */}
            <Field type="text" className="extra-fields-js form-control" name="flavorName"placeholder={validations.flavorNameDD} />
            {submitCount > 0 && errors.flavorName !== "" ? (
                <FieldErrorMessage error={errors.flavorName} />
            ) : null}
            {/* Eof flavor name */}
          </div>
          {/* Eof extra fields */}
        </div>
        {/* Eof InquiryType 100000003 */}

        {/* InquiryType 100000006 - Coupons/Rebates */}
        {/* <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000006"? "" : "toggle-hide")}>
            <p>
                {validations.rebateTxt01} <Link to={`mailto:InmarRebateCenter@inmar.com?subject=Rebate`} target="_blank" title="InmarRebateCenter@inmar.com">InmarRebateCenter@inmar.com</Link>.
            </p>
            <p>
                {validations.rebateTxt02} <Link to={`https://www.inmarrebates.com`} target="_blank" title="www.inmarrebates.com">www.inmarrebates.com</Link>.
            </p>
            <p>
                {validations.rebateTxt03_A} <Link to={`https://home.ibotta.com/`} target="_blank" title="Ibotta">Ibotta</Link>, {validations.rebateTxt03_B}
            </p>
        </div> */}
        {/* Eof InquiryType 100000006 */}

        {/* InquiryType 100000012 - Can't Find Products */}
        {/* <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000012"? "" : "toggle-hide")}>
            <p>
                {validations.cantFindTxt01_A} <Link to={`https://www.whiteclawzero.com/locator`} target="_blank" title="{validations.cantFindLocatorUrlTxt}">{validations.cantFindLocatorUrlTxt}</Link> {validations.cantFindTxt01_B}
            </p>
        </div> */}
        {/* Eof InquiryType 100000012 */}

        {/* InquiryType 100000002 - Ingredients/Nutrition Info */}
        {/* <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000002"? "" : "toggle-hide")}>
            <p>
                {validations.nutritionTxt01_A} <Link to={`https://ussupport.whiteclaw.com/en/support/home`} target="_blank" title="FAQ">{validations.nutritionFaqUrlTxt}</Link> {validations.nutritionTxt01_B}
            </p>
        </div> */}
        {/* Eof InquiryType 100000002 */}

        {/* InquiryType 100000005 - Sponsorship Request */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000005"? "" : "toggle-hide")}>
          <h3>{validations.sponsorTxt01}</h3>
          <p>{validations.sponsorTxt02}</p>
        </div>
        {/* Eof InquiryType 100000005 */}

        {/* InquiryType 100000007 - Partnership/Media Inquiry */}
        <div className={"hidden-block form-section-info pt-3 " + (inquiryTypeID === "100000007"? "" : "toggle-hide")}>
          <h3>{validations.partnerTxt01}</h3>
          <p>{validations.partnerTxt02}</p>
        </div>
        {/* Eof InquiryType 100000007 */}

        {/* InquiryType 100000008 - Expiration Date */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === "100000008"? "" : "toggle-hide")}>
          <p className="pb-3">
            {validations.expirationTxt01}
            &nbsp;
            {/*eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
            <a href="#" onClick={(e) => openWindowModal(e, 'batchimage')}>
              {validations.viewImage}
            </a>.
          </p>
          <p className='pb-3'>
            {validations.expirationTxt02}
          </p>
          <p className="form-btn-container pb-4">
            <button type="button" className="form-btn form-btn-long-text" onClick={(e) => openWindowModal(e, 'decipherbatchnumber')}>{validations.decipherBatch}</button>
          </p>

          {/* WindowModal Component Batch / Decipher */}
          <WindowModal
              showWindowModal={showWindowModal}
              closeWindowModal={closeWindowModal}
              windowModalType={windowModalType}
              lang={lang}
          />
        </div>
        {/* Eof InquiryType 100000008 */}

        {/* Do Not Sell or Share My Personal Information  */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000023' ? '' : 'toggle-hide')}>
          <div className="col-12">
            <h4>
              Certain state laws allow you to make a request related to the personal information that we have collected about you.
              You have the option to opt out of sale and sharing of your personal information, as required by applicable state laws.
              The information provided below will help us process your request. If you do not provide the information requested below,
              we may not be able to identify you and process your request to opt-out.  You request to opt-out of sale/sharing is browser
              and device specific.
              <br/>
              For more information about our privacy practices, please review our <a href='/privacy' target='_blank'>Privacy Policy</a>.
            </h4>
          </div>
        </div>

        {/* Delete My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000024' ? '' : 'toggle-hide')}>
          <div className="col-12">
            <h4>Subject to certain exceptions, you have the right to request that we delete any of your personal information. Once we receive your request and confirm your identity, we will review your request to see if an exception under applicable law allowing us to retain the information applies. We will delete or deidentify personal information not subject to one of these exceptions from our records. We do not discriminate against individuals who exercise their rights. Please know that deletion of your personal information may result in  less personalized experience as well as less communications with Mark Anthony.</h4>
          </div>
        </div>

        {/* Access My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000025' ? '' : 'toggle-hide')}>
          <div className="col-12">
            <h4>
              Request the specific pieces of Personal Information that we have collected about you for the last 12 months.
              To protect our customers’ personal information, we are required to verify your identify before we can act on your request.
            </h4>
          </div>
        </div>

        {/* Correct My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000026' ? '' : 'toggle-hide')}>
          <div className="col-12">
            <h4>
              Request the specific pieces of Personal Information that should be corrected.
              Data types to change include: First Name, Last Name, Mobile Phone Number, Date of Birth, Primary Email Address,
              Primary Mailing Address.
              Subject to certain exceptions, you have the right to request that we correct inaccurate personal information that we have collected about you.
              We may also request additional information showing that the information you want to correct is inaccurate
            </h4>
          </div>
        </div>

        {/* Transfer My Personal Information */}
        <div className={"hidden-block form-section-info " + (inquiryTypeID === '100000027' ? '' : 'toggle-hide')}>
          <div className="col-12">
            <h4>
              Request to produce your Personal Information in a structured, commonly used, exportable format that may be transferred to another business.
            </h4>
          </div>
        </div>

      </>
  )
}

export default HiddenFields

