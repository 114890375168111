// core
import React, { useEffect, useState } from "react";


// constants
import { NUTRITIONAL_TABLE_TEXT_EN, NUTRITIONAL_TABLE_TEXT_ES } from "../../constants";
import parse from "html-react-parser";


export const NutritionAndIngredientsTable = ({ slice }) => {
  const [currentLang, setCurrentLang] = useState('')

  const item = slice.items[0];
  // console.log(item)
  const translation = currentLang === "EN" ? NUTRITIONAL_TABLE_TEXT_EN  : NUTRITIONAL_TABLE_TEXT_ES

  const values = {
    calories : item.calories || 15,
    fat_grams: item.fat_g || 0,
    fat_percentage: item.fat_percentage || 0,
    saturated_grams: item.saturated_g || 0,
    saturated_percentage:item.saturated_percentage || 0,
    polyunsaturated: item.polyunsaturated_g || 0,
    polyunsaturated_percentage:item.polyunsaturated_percentage || 0,
    monounsaturated: item.monounsaturated_g || 0,
    monounsaturated_percentage:item.monounsaturated_percentage || 0,
    cholesterol_mgrams: item.cholesterol_mg|| 0,
    sodium_mgrams: item.sodium_mg || 65,
    sodium_percentage: item.sodium_percentage || 3,
    carbohydrate_grams: item.carbohydrate_g|| 5,
    carbohydrate_percentage: item.carbohydrate_percentage|| 2,
    fiber_grams: item.fibre_g || 0,
    fiber_percentage: item.fibre_percentage || 0,
    sugars_grams: item.sugars_g || 2,
    sugars_percentage: item.sugars_percentage || 4,
    protein_grams: item.protein_g || 0,
    protein_percentage: item.protein_percentage || 0,
    description: item.daily_value_description?.text || translation.description,
    daily_value: item.daily_value.text || parse(translation.dailyValue),
    ingredients: item.ingredients.text || ''
  }

  useEffect(()=>{
    setCurrentLang(item.page_lang)
  }, [])

  return (
      <>
        <tbody>
        <tr className="product-nutrition__row product-nutrition__row--no-underline">
          <td className="product-nutrition__row-left">{translation.amountPerServing}</td>

          <td className="product-nutrition__row-right"></td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--large-header product-nutrition__row--dark-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">Calories</td>
          <td className="product-nutrition__row-right">
            { values.calories }
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left"></td>
          <td className="product-nutrition__row-right">{translation.dailyValuePercentage}</td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.fat} { values.fat_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.fat_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.saturated} { values.saturated_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.saturated_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.polyunsaturated} { values.polyunsaturated } g
          </td>
          <td className="product-nutrition__row-right">
            { values.polyunsaturated_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.monounsaturated} { values.monounsaturated } g
          </td>
          <td className="product-nutrition__row-right">
            { values.monounsaturated_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.cholesterol} { values.cholesterol_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">0 %</td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.sodium} { values.sodium_mgrams } mg
          </td>
          <td className="product-nutrition__row-right">
            { values.sodium_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--light-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.carbohydrate} { values.carbohydrate_grams } g
          </td>
          <td className="product-nutrition__row-right"> { values.carbohydrate_percentage } %</td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.fiber} { values.fiber_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.fiber_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--indented product-nutrition__row--light-underline">
          <td className="product-nutrition__row-left">
            {translation.sugars} { values.sugars_grams } g
          </td>
          <td className="product-nutrition__row-right">
            { values.sugars_percentage } %
          </td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__row--dark-underline product-nutrition__row--bold-text-left">
          <td className="product-nutrition__row-left">
            {translation.protein} { values.protein_grams } g
          </td>
          <td className="product-nutrition__row-right">{ values.protein_percentage } %</td>
        </tr>
        <tr className="product-nutrition__row product-nutrition__footer product-nutrition__footer--first product-nutrition__row--light-underline">
          <td colSpan="2">
            <p>
              { values.description }
            </p>
          </td>
        </tr>
        <tr className="product-nutrition__footer product-nutrition__footer--first">
          <td colSpan="2">
            <p>
              { values.daily_value }
            </p>
          </td>
        </tr>
        <tr className="product-nutrition__footer">
          <td colSpan="2"><h3>{translation.ingredients}</h3></td>
        </tr>
        <tr className="product-nutrition__footer">
          <td colSpan="2">
            <p>
                { values.ingredients }
            </p>
          </td>
        </tr>
        </tbody>
      </>
  );
};

