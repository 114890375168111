// core
import React, { useEffect, useState } from "react"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"

// components
import AccordionItem from "./AccordionItem";

// slices
import { components } from "../../slices/products";

// constants
import { LANG } from "../../constants";
import {SliceZone} from "@prismicio/react";


const TabsComponent = ({ pageData, activeDocMeta }) => {
  const { lang } = activeDocMeta ? activeDocMeta : LANG.EN
  const isBrowser = typeof window !== "undefined"

  const [key, setKey] = useState("range")
  const [activeTabData, setActiveTabData] = useState([])

  const slices = pageData.body2

  const tab = {
    new: pageData.body,
    range: pageData.body1
  }

  useEffect(()=>{

    if(!isBrowser){
      return
    }
    setActiveTabData(tab.range)
  },[isBrowser])


  return (
      <><div className='w-100' key={key}>
        {/* Prod Page Nav */}
        <Tabs
            id="flavors-accordion"
            activeKey={key}
            key={"tabs"}
            onSelect={(k) => {setKey(k)

              if(k === "range") {
                setActiveTabData(pageData.body1)
              }

              if(k === "new") {
                setActiveTabData(pageData.body)
              }
            }}
            className="products-page-nav"
        >
          <Tab eventKey="viewby" title={lang === LANG.EN ? "VIEW BY:" : "VER POR:"} key={"tab1"} disabled></Tab>
          <Tab eventKey="range" title={lang === LANG.EN ? "RANGE" : "GAMA"}  key={"tab2"}>
            <AccordionItem currentBody={activeTabData}/>
          </Tab>

          {/* NEW TAB */}
          {/*<Tab eventKey="new" title={lang === LANG.EN ? "NEW" : "NUEVO"}  key={"tab3"}>*/}
          {/*  <AccordionItem currentBody={activeTabData}/>*/}
          {/*</Tab>*/}
        </Tabs>

      </div><SliceZone components={components} slices={slices} /></>

  )
}

export default TabsComponent
