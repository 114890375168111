import React from 'react'
import { Link } from 'gatsby'

import { linkResolver } from '../utils/linkResolver'
import { defaultLanguage } from '../../prismic-configuration'

import usFlag from '../assets/images/flags/US.svg'

// data-layer
import {setGlobalLangAndCountryEvent} from "../assets/js/data-layer";
import { useConsent } from '../components/ConsentContext'

// constants
import { LANG } from "../constants";

export const LanguageSwitcher = ({ activeDocMeta }) => {

  const currentDoc = activeDocMeta
  const currentType = currentDoc.type
  const currentLang = currentDoc.lang
  const alternateDoc = activeDocMeta.alternateLanguages[0]
  const consentAccepted = useConsent()

  return (
      <>
        <div className="col-md-8 d-flex">
          <div className="d-flex align-items-center">
            <img src={usFlag} alt="BE Flag" className="mr-2" width="60px" />
            <span>UNITED STATES</span>
            {
              currentType === 'error_page' ?
                  <ul className="language-selection">
                    <Link to='/' title="White Claw Zero US" onClick={
                      ()=>setGlobalLangAndCountryEvent(LANG.EN, consentAccepted)
                    }>
                      <li className={currentLang === LANG.EN ? 'active' : ''}>English</li>
                    </Link>
                    {/*<Link to='/es/' title="White Claw Zero ES" onClick={*/}
                    {/*  ()=>setGlobalLangAndCountryEvent(LANG.ES, consentAccepted)*/}
                    {/*}>*/}
                    {/*  <li className={currentLang === LANG.ES ? 'active' : ''}>Español</li>*/}
                    {/*</Link>*/}
                  </ul>
                  :
                  currentDoc.lang === defaultLanguage ?
                      <ul className="language-selection">
                        <Link to={currentDoc.url} title="White Claw Zero US" onClick={
                          ()=>setGlobalLangAndCountryEvent(LANG.EN, consentAccepted)
                        }>
                          <li className='active'>English</li>
                        </Link>
                        {/*<Link to={alternateDoc ? linkResolver(alternateDoc)  :  `/404`} title="White Claw Zero ES" onClick={*/}
                        {/*  ()=>setGlobalLangAndCountryEvent(LANG.ES, consentAccepted)*/}
                        {/*}>*/}
                        {/*  <li>Español</li>*/}
                        {/*</Link>*/}
                      </ul>
                      :
                      <ul className="language-selection">
                        <Link to={alternateDoc ? linkResolver(alternateDoc) :  `/404`} title="White Claw Zero US" onClick={
                          ()=>setGlobalLangAndCountryEvent(LANG.EN, consentAccepted)
                        }>
                          <li>English</li>
                        </Link>
                        {/*<Link to={currentDoc.url} title="White Claw Zero ES" onClick={*/}
                        {/*  ()=>setGlobalLangAndCountryEvent(LANG.ES, consentAccepted)*/}
                        {/*}>*/}
                        {/*  <li className='active'>Español</li>*/}
                        {/*</Link>*/}
                      </ul>
            }
          </div>
        </div>
      </>
  )
}
