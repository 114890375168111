import React, {useEffect, useState} from 'react'

// components
import CountrySelector from './CountrySelector'
import { Formik, Form, Field } from "formik"

// assets
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { checkDOB } from "../assets/js";
import globeBlack from '../assets/images/icons/globe.svg'

// constants
import { LANG, AGE_GATE_VALIDATION_ES, AGE_GATE_VALIDATION_EN} from '../constants'

// schemas
import { ageGateDOBFormValidationSchema } from '../schemas'

const AgeGate = ({setAgeValid , activeDocMeta}) => {
  const { lang } = activeDocMeta ? activeDocMeta : { lang: LANG.EN }
  const [minorMsg, setMinorMsg] = useState(false)

  const isEN = lang === LANG.EN
  const validations = isEN  ? AGE_GATE_VALIDATION_EN : AGE_GATE_VALIDATION_ES

  // country select functions
  const [showCountrySelector, setShowCountrySelector] = useState(false)
  const closeCountrySelector = () => setShowCountrySelector(false)
  const openCountrySelector = () => setShowCountrySelector(true)

  const handleOnSubmit = (data) => {
    const isValidDOB = checkDOB(data)

    if (isValidDOB) {
      setAgeValid(true);
    } else {
      setMinorMsg(true);
    }
  }

  useEffect(() => {
    document.querySelector('body').classList.add('modal-open')
    return () => {
      document.querySelector('body').classList.remove('modal-open')
    };
  }, []);


  return (
      <div className="modal modal-age-gate">
          <div className='container'>
            <div className='age-gate'>
              <div className="row position-relative justify-content-center w-100">
                <div className="align-self-center mx-auto my-4 my-lg-5">
                  <img src='/images/logo-blue.png'  className='age-gate-logo' alt="White Claw Logo"/>
                </div>
                {/*<button className="country-selector" onClick={openCountrySelector} onKeyDown={openCountrySelector}>*/}
                {/*  <img src={globeBlack} className="globe-black" alt="Globe Icon" />*/}
                {/*</button>*/}
              </div>

              <div className="container-fluid px-0">
                <div className="row w-lg-80 mx-auto">
                  <div className="age-gate-title-wrap col-12 py-3 px-3 py-lg-4 px-lg-4">
                    <h1 className="my-2">{!minorMsg ? validations.title : validations.invalidAge}</h1>
                  </div>
                </div>

                {!minorMsg && <div className="row w-lg-80 mx-auto age-gate-btns flex-column pt-3">
                  <Formik
                      initialValues={{ day: "", month: "", year: "" }}
                      validationSchema={ageGateDOBFormValidationSchema(validations)}
                      onSubmit={(values) => { handleOnSubmit(values)}}
                  >
                    {({ values, errors, touched, handleChange, handleBlur }) => (
                        <Form id="age-gate-form" className='age-gate-form' autoComplete="off">
                          <div className="row">
                            <div className="col-12 col-lg-4 mb-3" style={{order: isEN ? '1' : "2"}}>
                              {/* Month */}
                              <Field
                                  type="number"
                                  id="form-birthdate-month"
                                  className="form-control"
                                  name="month"
                                  placeholder="MM"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.month}
                                  min={1}
                                  max={12}
                              />
                              {errors.month && touched.month && <div className="text-danger mt-2">{errors.month}</div>}
                            </div>
                            <div className="col-12 col-lg-4 mb-3" style={{order: isEN ? '2' : "1"}}>
                              {/* Day */}
                              <Field
                                  type="number"
                                  id="form-birthdate-day"
                                  className="form-control"
                                  name="day"
                                  placeholder="DD"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.day}
                                  min={1}
                                  max={31}
                              />
                              {errors.day && touched.day && <div className="text-danger mt-2">{errors.day}</div>}
                            </div>

                            <div className="col-12 col-lg-4 mb-3" style={{order: "3"}}>
                              {/* Year */}
                              <Field
                                  type="number"
                                  id="form-birthdate-year"
                                  className="form-control"
                                  name="year"
                                  placeholder="YYYY"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.year}
                              />
                              {errors.year && touched.year && <div className="text-danger mt-2">{errors.year}</div>}
                            </div>
                          </div>
                          <div className="col-12 mb-4 form-btn-container">
                            {/* Submit Btn */}
                            <button className="form-btn mx-auto" type="submit" id="submit-contact-form">
                              {validations.submitBtn}
                            </button>
                          </div>
                        </Form>
                    )}
                  </Formik>
                </div>}

              </div>

              {/*<div className="region-select">*/}
              {/*  <h4>*/}
              {/*    <img src={globeBlack} className="globe-black" alt="Globe Icon" />*/}
              {/*    Country / Language*/}
              {/*  </h4>*/}

              {/*  <button type='button'*/}
              {/*          className="region-selected"*/}
              {/*          onClick={openCountrySelector}*/}
              {/*          onKeyDown={openCountrySelector}*/}
              {/*  >*/}
              {/*    <div className="region-selected__flag"></div>*/}

              {/*    <div className="region-selected__country">*/}
              {/*      <span className="country-name">{validations.countryName}</span>*/}
              {/*      <br/>*/}
              {/*      <span className="country-lang">{validations.countryLang}</span>*/}
              {/*    </div>*/}

              {/*    <FontAwesomeIcon icon={faChevronRight} className="fa-solid"/>*/}
              {/*  </button>*/}
              {/*</div>*/}
              <div className="row age-gate-terms py-2"> {validations.legalCopy}</div>
            </div>
          </div>
        <CountrySelector showCountrySelector={showCountrySelector} closeCountrySelector={closeCountrySelector} activeDocMeta={lang} />
      </div>
  )
}

export default AgeGate