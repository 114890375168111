import { dataTrack } from "./functions"

// global lang and country
const setGlobalLangAndCountryEvent = (lang, ota) => {
  dataTrack(undefined, {
    'country': 'US',
    'language': lang
  }, 'page', ota)
}

// social_icon_clicked
const setSocialIconClickedEvent = (data, ota) =>{

  const { platform, location } = data

  dataTrack('social_icon_clicked', {
    'social_platform': platform,
    'page_location': location
  }, 'track', ota)
}

// contact_us_form_submitted
const setContactUsFormSubmittedEvent = (data, ota)  => {

  const { topicId, topicName, batchn, sop, pt, pq, contact_us_flavor_name } = data

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'contact_us_form_submitted',
    'topic_id': topicId,
    'topic_name': topicName,
    'dtls': {
      'cus': {
        'batchn': batchn,
        'sop': sop,
        'pt': pt,
        'pq': pq,
        'contact_us_flavor_name': contact_us_flavor_name
      }
    }
  })
  if(ota) {
    window.analytics.track("Contact Us Form Submitted", {
      'topic_id': topicId,
      'topic_name': topicName,
      'batch_number': batchn,
      'state_of_purchase': sop,
      'package_type': pt,
      'package_quantity': pq,
      'contact_us_flavor_name': contact_us_flavor_name
    })
  }
}

// email_subscription_signup
const setSubscriptionSignUpEvent = (location, ota)  => {
  dataTrack('email_subscription_signup', {
    'page_location': location,
  }, 'track', ota)
}

// product_flavor_clicked
const setProductFlavorClickedEvent = (data, ota) =>{

  const {flavor, category, position, url, location, size} = data

  dataTrack('product_flavor_clicked', {
    'product_flavor': flavor,
    'product_category': category,
    'product_position': position,
    'page_url': url,
    'page_location': location,
    'product_size': size
  }, 'track', ota)
}

// social_post_clicked
const setSocialPostClickedEvent = (data, ota) =>{

  const { link, position } = data

  dataTrack('social_post_clicked', {
    'outbound_link': link,
    'tile_position': position
  }, 'track', ota)
}

// product_flavor_viewed
const setProductFlavorViewedEvent = (data, ota) =>{

  const {flavor, category, size} = data

  dataTrack('product_viewed', {
    'page_category': 'PDP',
    'product_flavor': flavor,
    'product_category': category,
    'product_size': size
  }, 'track', ota)
}

// nutrition_ingredients_clicked
const setNutritionIngClickedEvent = (product, ota)  => {
  dataTrack('nutrition_ingredients_clicked', {
    'product_flavor': product.name,
    'product_category': product.category
  }, 'track', ota)
}

// learn_more_clicked
const setLearnMoreClickedEvent = (data, ota)  => {

  const { url, referrer, name } = data

  // wait OneTrustCookie
  setTimeout(()=> {
    dataTrack('learn_more_clicked', {
      'click_url': url,
      'button_name': name,
      'referrer': referrer
    }, 'track', ota)
  }, 350)
}

// category_viewed
const setCategoryViewedEvent = (data, ota) =>{

  // Add tab in accordion item if we get range/new 
  const {category, tab } = data

  dataTrack('category_viewed', {
    'category': category,
  }, 'track', ota)
}

// locator pageview event
const setLocatorPageViewEvent = (data, ota) => {
  const { language, title, url, path, referrer } = data

  dataTrack('locator_pageview', {
    'page_title': title,
    'page_url': url,
    'page_path': path,
    'referrer': referrer,
    'language': language,
    'country': "US"
  }, 'track', ota)
}

// retailer selected event - MikMak
const setRetailerSelectedEvent = (data, ota) => {
  const { supplier, flavor, category, size, price } = data

  dataTrack('locator_retailer_selected', {
    'supplier_name': supplier,
    'product_flavor': flavor,
    'product_category': category,
    'product_size': size,
    'product_price': price
  }, ' track', ota)
}

// locator address searched event - MikMak
const setLocatorAddressSearchedEvent = (data, ota) => {
  const { zc, cty, st, ctry, addr } = data
  const dtls = {
    'cus': {
      'addr': addr? addr.toLowerCase() : '',
      'cty': cty? cty.toLowerCase() : '',
      'st': st? st.toLowerCase() : '',
      'ctry': ctry? ctry : '',
      'zc': zc.toLowerCase()
    }
  }

  dataTrack('locator_address_searched', {
    'dtls': dtls
  }, 'track', ota)
}

// locator flavor selected event
const setLocatorFlavorSelectedEvent = (data, ota) => {
  // const {flavor, category, size, method } = data

  // dataTrack('locator_flavor_selected', {
  //   'product_flavor': flavor,
  //   'product_category': category,
  //   'product_size': size,
  //   'search_method': method
  // }, ' track', ota)
}

// buy now clicked event
const setBuyNowClickedEvent = (data, ota) => {
  const {flavor, category} = data

  dataTrack('buy_now_clicked', {
    'product_flavor': flavor,
    'product_category': category
  }, 'track', ota)
}

// get directions
const setGetDirectionsEvent = (data, ota) => {
  const { supplier, url } = data

  dataTrack('get_directions', {
    'page_url': url,
    'supplier_name': supplier
  }, 'track', ota)
}

export {
  setGlobalLangAndCountryEvent,
  setSocialIconClickedEvent,
  setContactUsFormSubmittedEvent,
  setProductFlavorClickedEvent,
  setSubscriptionSignUpEvent,
  setSocialPostClickedEvent,
  setProductFlavorViewedEvent,
  setNutritionIngClickedEvent,
  setLearnMoreClickedEvent,
  setCategoryViewedEvent,
  setLocatorPageViewEvent,
  setRetailerSelectedEvent,
  setLocatorAddressSearchedEvent,
  setLocatorFlavorSelectedEvent,
  setBuyNowClickedEvent,
  setGetDirectionsEvent
}